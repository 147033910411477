<template>
  <div
    v-if="direction == 'horizontal'"
    clas="p-d-flex p-flex-column"
    style="width:50px;"
  >
    <div class="p-d-flex p-flex-row p-jc-between" style="width:50px;">
      <span style="font-size: 8px;" :style="currentHorizontalPercentageMargin"
        >{{ percentage.toFixed(2) }}%</span
      >
    </div>

    <div
      class="info-box-outlined-slim-gray"
      style="width:50px; height:3px; margin-left: 6px"
    >
      <span style="font-size: 7px; margin-top:-20px">{{
        horizontalTitle
      }}</span>
    </div>

    <div
      class="up-triangle no-caret"
      style="width: 12px; height: 7px; background-color: #f05c15;"
      :style="currentHorizontalPercentageMargin"
    ></div>
  </div>

  <!-- VERTICAL VARIATION -->
  <div
    v-if="direction == 'vertical'"
    clas="p-d-flex p-flex-column"
    style="height:40px; margin-top: -12px"
  >
    <span style="font-size: 8px;" :style="currentVerticalPercentageMargin"
      >{{ percentage.toFixed(2) }}%</span
    >
    <!-- <div class="p-d-flex p-flex-row">
      <div
        class="info-box-outlined-slim-gray"
        style="width:3px; height:40px; margin-bottom: -6px"
      ></div>

    </div> -->
    <div
      class="info-box-outlined-slim-gray"
      style="width:3px; height:40px; margin-bottom: -6px"
    >
      <span class="p-ml-1" style="font-size: 7px; margin-top:-20px;"
        >{{ verticalTitle }}
      </span>
    </div>

    <div
      class="left-triangle no-caret"
      style="width: 12px; height: 7px; background-color: #0da4fa;"
      :style="currentVerticalPercentageMargin"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    direction: { type: String },
    percentage: { type: Number },
    horizontalTitle: { type: String },
    verticalTitle: { type: String }
  },
  computed: {
    currentHorizontalPercentageMargin() {
      return "margin-left: " + this.percentage / 2 + "px";
    },
    currentVerticalPercentageMargin() {
      return (
        "margin-top: " +
        (0.4 * this.percentage + Math.pow(1.065814, -14)) * -1 +
        "px"
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
