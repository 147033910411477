<template>
  <div
    class="p-col-12 p-xl-8 p-lg-8 p-md-12 p-xs-12 p-mt-2"
    style="padding: 0px"
  >
    <div class="p-grid" v-if="!snap">
      <div class="p-col-2 p-xl-2 p-lg-2 p-md-2 info-box-title-box">
        <div
          class="p-d-flex p-jc-center control-box no-caret"
          style="width: 100%; height: 22px;"
        >
          <span class="p-as-center">
            <!-- <b class="dynamic-info-box-title-fonts"><a class="axxalon-a">{{ panelTitle }}</a> {{ panelSubtitle }}</b> -->
            <b class="dynamic-info-box-title-fonts"><a class="axxalon-a-red">{{ panelTitle }}</a> {{ panelSubtitle }}</b>
          </span>
        </div>
      </div>
      <div class="p-col-2 p-xl-2 p-lg-3 p-md-3 info-box-title-subbox">
        <!-- <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box no-caret"
          style="width: 100%; height:22px;"
        > -->
        <AxxalonTooltip
              :tooltipText="unitsExplanationTooltip"
              direction="bottom"
              style="width: 100% !important;"
            >
        <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box-red no-caret"
          style="width: 100%; height:22px;"
        >
          <span class="p-ml-2"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              FirstFieldTitle
            }}</b></span
          >

          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="FirstFieldId"
              style="color: black"
              >{{ numberFormatThousands(0) }}</b
            >
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px;"
            >
              {{ FirstFieldValueUnit }}</b
            ></span
          >
        </div>
         </AxxalonTooltip>
      </div>
      <div class="p-col-2 p-xl-2 p-lg-3 p-md-3 p-ml-2 info-box-title-subbox">
        <!-- <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box no-caret"
          style="width: 100%; height:22px;"
        > -->
        <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box-red no-caret"
          style="width: 100%; height:22px;"
        >
          <span class="p-ml-2"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              SecondFieldTitle
            }}</b></span
          >
          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="SecondFieldId"
              style="color: black"
              >{{ 0 }}
            </b>
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px;"
            >
              {{ SecondFieldValueUnit }}</b
            ></span
          >
        </div>
      </div>
      <div class="p-col-2 p-xl-2 p-lg-3 p-md-3 p-ml-2 info-box-title-subbox">
        <!-- <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box no-caret"
          style="width: 100%; height:22px;"
        > -->
        <div
          class="p-d-flex p-flex-row p-jc-center cutted-t-right info-box-red no-caret"
          style="width: 100%; height:22px;"
        >
          <span class="p-ml-2"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              ThirdFieldTitle
            }}</b></span
          >
          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="ThirdFieldId"
              style="color: black"
              >{{ 0 }}
            </b>
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px;"
            >
              {{ ThirdFieldValueUnit }}</b
            ></span
          >
        </div>
      </div>

      <div v-if="hideInteractiveControls" class="p-d-flex p-flex-column p-mb-4">
        <span class="p-ml-4 no-caret"
          ><b
            >{{ noDateTimeWarningPiece0Text }}
            <a class="axxalon-a-red">{{ noDateTimeWarningPiece1Text }}</a>
            {{ noDateTimeWarningPiece2Text }}
            <a class="axxalon-a-red">{{ noDateTimeWarningPiece3Text }}</a>
            {{ noDateTimeWarningPiece4Text }}
            <a class="axxalon-a-red">{{ noDateTimeWarningPiece5Text }}</a
            >.</b
          ></span
        >
        <span class="p-ml-4 no-caret" style="font-size: 10px"
          ><b
            ><a>{{ noMetricsCalculationText }}</a></b
          ></span
        >
      </div>

      <!-- CONTROL BUTTONS -->

      <div
        v-if="!hideInteractiveControls"
        class="p-col-4"
        style=" padding: 0px; padding-left: 7px"
      >
        <div class="p-d-flex p-flex-row">
          <div class="p-d-flex p-flex-column p-mr-1 p-ml-2">
            <div class="p-d-flex p-flex-row">
              <div
                class="p-col-2 p-mb-1 p-mr-1"
                style="width:30px; height:30px; padding: 0px"
              >
                <!-- HIDE/SHOW RESOURCES -->
                <AxxalonTooltip
                  :tooltipText="hideShowResourcesTooltip"
                  :disabled="searchMode"
                  direction="right"
                >
                  <Button
                    :class="
                      rowLabelWidth > 0
                        ? 'axxalon-a-red control-button'
                        : 'control-button-pressed-red'
                    "
                    :disabled="inactiveControlsState || searchMode"
                    class="cutted-b-right-var p-jc-center"
                    style="--notchSize: 5px; font-weight: bold;  width:30px; height:30px;"
                    label="R"
                    @click="onRowLabelWidthButtonClick"
                  />
                </AxxalonTooltip>
              </div>
              <div
                class="p-col-2  p-mb-1 p-mr-1"
                style="width:30px; height:30px; padding: 0px"
              >
                <!-- HIDE/SHOW TIMEAXIS -->
                <AxxalonTooltip
                  :tooltipText="hideShowTimeAxisTooltip"
                  :disabled="searchMode"
                  direction="top"
                >
                  <Button
                    :class="
                      hideTimeaxis ? 'control-button-pressed-red' : 'control-button'
                    "
                    :disabled="inactiveControlsState || viewportTotalDays > 15 || searchMode"
                    class="cutted-b-left-var p-jc-center"
                    style="--notchSize: 5px; width:30px; height:30px;"
                    icon="pi pi-calendar"
                    @click="onHideTimeaxisButtonClick"
                  />
                </AxxalonTooltip>
              </div>
            </div>

            <div class="p-d-flex p-flex-row">
              <div
                class="p-col-2 p-mb-1 p-mr-1"
                style="width:30px; height:30px; padding: 0px"
              >
                <!-- SORT BY ORDER -->
                <AxxalonTooltip
                  :tooltipText="sortByOrderTooltip"
                  :disabled="searchMode"
                  direction="right"
                >
                  <Button
                    :class="
                      sortByOrder
                        ? 'control-button-pressed-red'
                        : 'axxalon-a-red control-button'
                    "
                    :disabled="inactiveControlsState || searchMode"
                    class="cutted-t-right-var p-jc-center"
                    style="--notchSize: 5px; font-weight: bold; width:30px; height:30px;"
                    icon="pi pi-sort"
                    @click="onSortByOrder"
                  />
                </AxxalonTooltip>
              </div>
              <div
                class="p-col-2 p-mb-1 p-mr-1"
                style="width:30px; height:30px; padding: 0px"
              >
                <!-- EXPAND -->
                <AxxalonTooltip
                  :tooltipText="expandChartTooltip"
                  direction="top"
                >
                  <Button
                    :class="
                      expanded ? 'control-button-pressed-red' : 'control-button'
                    "
                    :disabled="inactiveControlsState"
                    class="cutted-t-left-var p-jc-center"
                    style="--notchSize: 5px;  width:30px; height:30px;"
                    icon="pi pi-window-maximize"
                    @click="onExpand"
                  />
                </AxxalonTooltip>
              </div>
            </div>
          </div>

          <div
            class="p-col-12"
            style="height:50px; margin-top: -0px; padding: 0px"
          >
            <div class="p-d-flex p-flex-row">
              <div class="p-col-12 p-d-flex p-flex-column">
                <div
                  class="p-d-flex p-jc-between"
                  style="width: 90px; height: 22px;"
                >
                  <div class="p-d-flex p-flex-row">
                    <span
                      class="p-as-center control-box-secondary"
                      style="padding: 2px;"
                      ><b>{{ searchText }}</b>
                    </span>

                    <div
                      class="p-as-center control-box-secondary"
                      style="padding: 2px; color: dodgerblue !important"
                    >
                      <i
                        class="pi pi-info-circle"
                        @mouseover="showUpperSearchInfo"
                        @mouseleave="hideUpperSearchInfo"
                      >
                        <OverlayPanel ref="upperSearchInfo" appendTo="body">
                          <div class="p-d-flex p-flex-column">
                            <p style="font-size: 12px">
                              <b
                                >{{ searchTutorialPieceA0Text }}
                                <a class="axxalon-a-red">{{
                                  searchTutorialPieceA1Text
                                }}</a>
                                {{ searchTutorialPieceA2Text }}</b
                              >
                            </p>
                            <img
                              src="../../../assets/images/search_tutorial_01.png"
                              style="width: 100%; height: auto"
                            />
                            <span style="font-size: 10px"
                              ><b
                                ><a>{{ searchTutorialObservation0Text }}</a></b
                              ></span
                            >
                            <p class="p-mt-4" style="font-size: 12px">
                              <b
                                >{{ searchTutorialPieceB0Text }}
                                <a class="axxalon-a-red">{{
                                  searchTutorialPieceB1Text
                                }}</a
                                >#<a class="axxalon-a-red">{{
                                  searchTutorialPieceB2Text
                                }}</a>
                                {{ searchTutorialPieceB3Text }} <br />{{
                                  searchTutorialPieceB4Text
                                }}.</b
                              >
                            </p>
                            <img
                              src="../../../assets/images/search_tutorial_02.png"
                              style="width: 100%; height: auto"
                            />
                            <span style="font-size: 10px"
                              ><b
                                ><a>{{ searchTutorialObservation1Text }}</a></b
                              ></span
                            >
                          </div>
                        </OverlayPanel>
                      </i>
                    </div>

                    <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="linkTopAndBottomChartsTooltip"
                        :disabled="searchMode"
                        direction="top"
                      >
                        <Button
                          :class="
                            linked ? 'control-button-pressed-red' : 'control-button'
                          "
                          :disabled="
                            !isOptimizationLoaded || inactiveControlsState  || searchMode
                          "
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi pi-link"
                          @click="changeLink"
                        />
                      </AxxalonTooltip>
                    </div>
                    <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="restartChartsTooltip"
                        :disabled="searchMode"
                        direction="top"
                      >
                        <Button
                          :class="
                            restartButtonClicked
                              ? 'control-button-pressed-red'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || searchMode"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi pi-replay"
                          @click="restartCharts"
                        />
                      </AxxalonTooltip>
                    </div>
                    <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="turnGridOnOffTooltip"
                        :disabled="searchMode"
                        direction="top"
                      >
                        <Button
                          :class="
                            gridButtonClicked
                              ? 'control-button-pressed-red'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || viewportTotalDays > 18 || searchMode"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi pi-table"
                          @click="onChangeGridView"
                        />
                      </AxxalonTooltip>
                    </div>
                    <!-- <div style="padding: 1px">
                      <Button
                        :class="
                          tagsButtonClicked
                            ? 'control-button-pressed'
                            : 'control-button'
                        "
                        :disabled="inactiveControlsState"
                        class="cutted-b-right-var p-jc-center"
                        style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                        icon="pi pi-tags"
                        @click="onTags"
                      />
                    </div> -->
                    <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="turnShiftsOnOffTooltip"
                        :disabled="searchMode"
                        direction="top"
                      >
                        <Button
                          :class="
                            shiftsViewButtonClicked
                              ? 'control-button-pressed-red'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || viewportTotalDays > 65 || searchMode"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi  pi-map"
                          @click="onChangeShiftsView"
                        />
                      </AxxalonTooltip>
                    </div>
                    <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="activateGlobalScalingTooltip"
                        :disabled="searchMode"
                        direction="top"
                      >
                        <Button
                          :class="
                            globalScaleViewButtonClicked
                              ? 'control-button-pressed-red'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || searchMode"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi  pi-globe"
                          @click="onChangeGlobalScale"
                        />
                      </AxxalonTooltip>
                    </div>
                    <div v-if="isOptimizationLoaded" style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="snapDataOnViewportTooltip"
                        :disabled="searchMode"
                        direction="right"
                      >
                        <Button
                          :class="
                            snapDataOnViewportButtonClicked
                              ? 'control-button-pressed-red'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || !linked || searchMode"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi  pi-external-link"
                          @click="onSnapDataOnViewport"
                        />
                      </AxxalonTooltip>
                    </div>
                    <!--   ////////////////////////// -->
                    <!--  // CLEAR SEARCH BUTTON      -->
                    <!-- ///////////////////////////  -->
                    <div v-if="searchMode" style="padding: 1px">
                        <Button
                          v-if="!setClearMode"
                          label="clear search"
                          :class="clearSearchButtonClicked ? 'clear-search-button-clicked cutted-dual-variable-clock-wise p-button-sm p-d-flex' : 'clear-search-button cutted-dual-variable-clock-wise p-button-sm p-d-flex'"
                          style="--notchSize: 5px; font-weight: bold;"
                          :disabled="inactiveControlsState"
                          icon="pi  pi-times"
                          @click="onClearSearch"
                        />
                    </div>
                  </div>
                </div>
                <AxxalonInputText
                  :inputDisabled="inactiveControlsState"
                  type="text"
                  :placeholder="searchBoxPlaceholder"
                  iconPos="left"
                  iconType="pi-search"
                  :required="true"
                  :activateEnterKeyUp="true"
                  :resetSearchInputText="internalActivateReset"
                  @enterPressed="searchGantt"
                ></AxxalonInputText>
              </div>
            </div>
          </div>
          <div
            class="p-col-6 viewport-display p-mr-6"
            style="height:50px; padding: 7px;"
          >
            <div class="p-d-flex p-flex-row">
              <div class="p-d-flex p-flex-column">
                <div class="p-d-flex p-flex-row">
                  <div
                    class="p-ml-2 info-box-smalldetail-gray no-caret "
                    style="width: 30px"
                  ></div>
                  
                  <span class="p-ml-2" style="font-size: 8px"
                    ><b>{{ viewportText }}</b></span
                  >
                  <span class="p-ml-auto" style="font-size: 9px">{{ viewportTotalDays }} <span style="font-size: 8px">{{ viewportDaysText }}</span></span>
                </div>

                <div class="info-box-outlined-gray">
                  <div class="p-d-flex p-flex-row p-jc-around">
                    <span style="font-size: 9px; color: #6d747e"
                      ><b>{{ chartStart }}</b></span
                    >
                    <span style="font-size: 9px; color: #6d747e;"
                      ><b>{{ chartEnd }}</b></span
                    >
                  </div>
                </div>

                <div class="p-d-flex p-flex-row">
                  <div
                    class="p-col-2 p-mr-1 p-ml-2"
                    style="width:25px; height:25px; padding: 1px"
                  >
                    <!-- MINUS SCALE -->
                    <AxxalonTooltip
                      :tooltipText="decreaseChartScaleTooltip"
                      :disabled="searchMode"
                      direction="top"
                    >
                      <Button
                        :class="
                          decreaseScaleButtonClicked
                            ? 'control-button-pressed-red'
                            : 'control-button'
                        "
                        :disabled="inactiveControlsState || searchMode"
                        style="width:25px; height:25px; --notchSize: 5px"
                        class="cutted-b-right-var p-jc-center"
                        icon="pi pi-minus-circle"
                        @click="decreaseScale"
                      />
                    </AxxalonTooltip>
                  </div>
                  <div
                    class="p-col-2 p-mr-1"
                    style="width:25px; height:25px; padding: 1px"
                  >
                    <!-- PLUS SCALE -->
                    <AxxalonTooltip
                      :tooltipText="increaseChartScaleTooltip"
                      :disabled="searchMode"
                      direction="top"
                    >
                      <Button
                        :class="
                          increaseScaleButtonClicked
                            ? 'control-button-pressed-red'
                            : 'control-button'
                        "
                        :disabled="inactiveControlsState || searchMode"
                        style="width:25px; height:25px; --notchSize: 5px"
                        class="cutted-b-right-var p-jc-center"
                        icon="pi pi-plus-circle"
                        @click="increaseScale"
                      />
                    </AxxalonTooltip>
                  </div>
                  <div
                    class="p-col-2 p-mr-1"
                    style="width:25px; height:25px; padding: 1px"
                  >
                    <!-- WALK LEFT -->
                    <AxxalonTooltip
                      :tooltipText="walkLeftOnChartTooltip"
                      :disabled="searchMode"
                      direction="top"
                    >
                      <Button
                        :class="
                          walkLeftButtonClicked
                            ? 'control-button-pressed-red'
                            : 'control-button'
                        "
                        :disabled="inactiveControlsState || searchMode"
                        style="width:25px; height:25px; --notchSize: 5px"
                        class="cutted-b-right-var p-jc-center"
                        icon="pi pi-angle-left"
                        @mousedown="walkLeftHold"
                        @mouseleave="stop"
                        @mouseup="stop"
                        @touchend="stop"
                        @touchcancel="stop"
                      />
                    </AxxalonTooltip>
                  </div>
                  <div
                    class="p-col-2 p-mr-1"
                    style="width:25px; height:25px; padding: 1px"
                  >
                    <!-- WALK RIGHT -->
                    <AxxalonTooltip
                      :tooltipText="walkRightOnChartTooltip"
                      :disabled="searchMode"
                      direction="top"
                    >
                      <Button
                        :class="
                          walkRightButtonClicked
                            ? 'control-button-pressed-red'
                            : 'control-button'
                        "
                        :disabled="inactiveControlsState || searchMode"
                        style="width:25px; height:25px; --notchSize: 5px"
                        class="cutted-b-right-var p-jc-center"
                        icon="pi pi-angle-right"
                        @mousedown="walkRightHold"
                        @mouseleave="stop"
                        @mouseup="stop"
                        @touchend="stop"
                        @touchcancel="stop"
                      />
                    </AxxalonTooltip>
                  </div>
                  <div
                    class="p-col-2 p-ml-1 p-mr-1"
                    style="width:50px; height:50px; padding: 1px"
                  >
                    <!-- BAR INDICATOR -->
                    <AxxalonBarPercentageIndicator
                      :percentage="viewportPercentage"
                      :direction="'horizontal'"
                      :horizontalTitle="timeWindowIndicatorText"
                    />
                  </div>
                </div>
              </div>

              <div class="p-col-1 p-ml-4">
                <AxxalonBarPercentageIndicator
                  :percentage="parseFloat((scale * 100).toFixed(0))"
                  :direction="'vertical'"
                  :verticalTitle="scaleIndicatorText"
                />
              </div>
            </div>
          </div>

          <div
            v-if="deliveryReliability > -1.0"
            class="p-col-3 p-ml-6"
            style="padding: 0px; margin-left: 150px; !important"
          >
            <div class="p-d-flex p-flex-column p-jc-center">
              <div class="p-as-center" >
                <Knob
                  :modelValue="
                    parseFloat((deliveryReliability * 100).toFixed(2))
                  "
                  :size="50"
                  valueTemplate="{value}%"
                  readonly
                  valueColor="MediumTurquoise"
                  rangeColor="SlateGray"
                  class="no-caret"
                />
              </div>

              <span class="p-as-center no-caret" style="font-size: 8px">
                {{ deliveryReliabilityText }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ##################################### -->
    <!-- SIMPLIFIED CONTROLS FOR SNAP SCENARIO -->
    <!-- ##################################### -->
    <div class="p-grid p-mb-2" v-if="snap">
      <!-- CONTROL BUTTONS -->
      <div
        v-if="!hideInteractiveControls"
        class="p-col-12"
        style=" padding: 0px; padding-left: 7px;"
      >
        <div class="p-d-flex p-flex-row">

          <div class="p-col-2 p-xl-2 p-lg-2 p-md-2 info-box-title-box">
              <div
                class="p-d-flex p-jc-center control-box no-caret"
                style="width: 100%; height: 22px;"
              >
                <span class="p-as-center"
                  ><b class="dynamic-info-box-title-fonts"
                    ><a class="axxalon-a-red">{{ panelTitle }}</a> {{ panelSubtitle }}</b
                  >
                </span>
              </div>
          </div>


          <div
            class="p-col-12"
            style="height:35px; margin-top: -0px; padding: 0px"
          >
            <div class="p-d-flex p-flex-row">
              <div class="p-col-12 p-d-flex p-flex-column" >
 
                  <div class="p-d-flex p-flex-row">

                    <!-- <div style="padding: 1px">
                      <AxxalonTooltip
                        :tooltipText="exitSnapDataOnViewportTooltip"
                        direction="right"
                      >
                        <Button
                          :class="
                            snapDataOnViewportButtonClicked
                              ? 'control-button-pressed'
                              : 'control-button'
                          "
                          :disabled="inactiveControlsState || !linked"
                          class="cutted-b-right-var p-jc-center"
                          style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                          icon="pi  pi-external-link"
                          @click="onSnapDataOnViewport"
                        />
                      </AxxalonTooltip>
                    </div> -->

              <!-- INSERIR AQUI O VIEWPORT -->
            <div class="p-d-flex p-flex-row p-ml-2">
              <div class="p-d-flex p-flex-column">
                <div class="p-d-flex p-flex-row">
                  <div
                    class="p-ml-2 info-box-smalldetail-gray no-caret "
                    style="width: 30px"
                  ></div>
                  
                  <div class="p-d-flex p-flex-column">
                  <span class="p-ml-2" style="font-size: 8px"
                    ><b>{{ viewportText }}</b>
                  </span>
                  <span class="p-ml-2" style="font-size: 9px">{{ viewportTotalDays }} <span style="font-size: 8px">{{ viewportDaysText }}</span></span>
                  </div>


                <div class="info-box-outlined-gray-brighter p-ml-2 p-jc-around">
                  <div class="p-d-flex p-flex-row p-jc-around">
                    <span class="p-ml-1 p-mt-1" style="font-size: 9px; color: #6d747e"
                      ><b>{{ chartStart }}</b></span
                    >
                    <span class="p-ml-2 p-mr-1 p-mt-1" style="font-size: 9px; color: #6d747e;"
                      ><b>{{ chartEnd }}</b></span
                    >
                  </div>
                </div>

                </div>


              </div>
            </div>


              <div class="p-ml-2" style="padding: 1px">
                <AxxalonTooltip
                  :tooltipText="exitSnapDataOnViewportTooltip"
                  direction="bottom"
                >
                  <Button
                    :class="
                      snapDataOnViewportButtonClicked
                        ? 'control-button-pressed-red'
                        : 'control-button'
                    "
                    :disabled="inactiveControlsState || !linked"
                    class="cutted-b-right-var p-jc-center"
                    style="--notchSize: 5px; font-weight: bold;  width:20px; height:20px;"
                    icon="pi  pi-external-link"
                    @click="onSnapDataOnViewport"
                  />
                </AxxalonTooltip>
              </div>


          <div class="p-ml-6">
            <span class="p-ml-4"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              FirstFieldTitle
            }}</b></span
          >

          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="FirstFieldId"
              style="color: darkorange"
              ><a class="axxalon-a-red">{{ numberFormatThousands(0) }}</a></b
            >
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px; color: darkgray"
            >
              {{ FirstFieldValueUnit }}</b
            ></span>
          </div>
          
          <div class="p-ml-4">
            <span class="p-ml-2"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              SecondFieldTitle
            }}</b></span
          >
          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="SecondFieldId"
              style="color: darkorange"
              ><a class="axxalon-a-red">{{ 0 }}</a>
            </b>
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px; color: darkgray"
            >
              {{ SecondFieldValueUnit }}</b
            ></span>
          </div>

          <div class="p-ml-4">
              <span class="p-ml-2"
            ><b class="dynamic-info-box-fonts" style="font-size: 9px;">{{
              ThirdFieldTitle
            }}</b></span
          >
          <span class="p-ml-2"
            ><b
              class="dynamic-info-box-numbers-fonts"
              :id="ThirdFieldId"
              style="color: darkorange"
              ><a class="axxalon-a-red">{{ 0 }}</a>
            </b>
            <b
              class="p-ml-2 dynamic-info-box-sub-fonts"
              style="font-size: 8px; color: darkgray"
            >
              {{ ThirdFieldValueUnit }}</b
            ></span>
          </div>

          <div
            v-if="deliveryReliability > -1.0"
            class="p-ml-4"
            style="padding: 0px"
          >
              <span class="p-as-center no-caret" style="font-size: 9.5px">
                {{ deliveryReliabilityText }}: <b><a class="axxalon-a-blue">{{(deliveryReliability * 100).toFixed(2)}}%</a></b>
              </span>
          </div>
          
          <!-- <div
            v-if="deliveryReliability > -1.0"
            class="p-col-3 p-ml-4"
            style="padding: 0px"
          >
            <div class="p-d-flex p-flex-column p-jc-center">
              <div class="p-as-center">
                <Knob
                  :modelValue="
                    parseFloat((deliveryReliability * 100).toFixed(2))
                  "
                  :size="30"
                  valueTemplate="{value}%"
                  readonly
                  valueColor="MediumTurquoise"
                  rangeColor="SlateGray"
                  class="no-caret"
                />
              </div>

              <span class="p-as-center no-caret" style="font-size: 8px">
                {{ deliveryReliabilityText }}: {{(deliveryReliability * 100).toFixed(2)}}
              </span>
            </div>
          </div> -->

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AxxalonInputText from "@/components/Axxalon/AxxalonInputText.vue";
import AxxalonBarPercentageIndicator from "@/components/Axxalon/AxxalonDashboardControls/AxxalonBarPercentageIndicator.vue";
import moment from "moment";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  emits: [
    "updateScale",
    "updateLink",
    "updateWalk",
    "updateViewport",
    "globalViewportUpdate",
    "updateViewportPercentage",
    "updateWalkLowerPagination",
    "updateWalkUpperPagination",
    "restartViewport",
    "rowLabelWidth"
  ],
  props: {
    panelCode: { type: String },
    panelTitle: { type: String },
    panelSubtitle: { type: String },
    FirstFieldTitle: { type: String },
    FirstFieldValueUnit: { type: String },
    FirstFieldId: { type: String },
    SecondFieldTitle: { type: String },
    SecondFieldValueUnit: { type: String },
    SecondFieldId: { type: String },
    ThirdFieldTitle: { type: String },
    ThirdFieldValueUnit: { type: String },
    ThirdFieldId: { type: String },
    originChartStart: { type: String },
    originChartEnd: { type: String },
    chartStart: { type: String },
    chartEnd: { type: String },
    siblingChartStart: { type: String },
    siblingChartEnd: { type: String },
    rowLabelWidth: { type: Number },
    linked: { type: Boolean },
    viewportPercentage: { type: Number },
    scale: { type: Number },
    hideTimeaxis: { type: Boolean },
    isOptimizationLoaded: { type: Boolean },
    shiftFunction: { type: Function },
    snapFunction: { type: Function },
    searchFunction: { type: Function },
    sortingFunction: { type: Function },
    resetFields: { type: Boolean },
    activateReset: { type: Boolean },
    shiftButtonActivated: { type: Boolean },
    sortedButtonActivated: { type: Boolean },
    hideInteractiveControls: { type: Boolean },
    deliveryReliability: { type: Number },
    searchMode: {type: Boolean},
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      // linked: true,
      localLanguage: "de",

      unitsExplanationTooltip: this.t(
        "units_explanation_tooltip",
        {},
        { locale: "de" }
      ),

      hideShowResourcesTooltip: this.t(
        "hide_show_resources_tooltip",
        {},
        { locale: "de" }
      ),
      hideShowTimeAxisTooltip: this.t(
        "hide_show_time_axis_tooltip",
        {},
        { locale: "de" }
      ),
      sortByOrderTooltip: this.t("sort_by_order_tooltip", {}, { locale: "de" }),
      expandChartTooltip: this.t("expand_chart_tooltip", {}, { locale: "de" }),
      linkTopAndBottomChartsTooltip: this.t(
        "link_top_and_bottom_charts_tooltip",
        {},
        { locale: "de" }
      ),
      restartChartsTooltip: this.t(
        "restart_chart_tooltip",
        {},
        { locale: "de" }
      ),
      turnGridOnOffTooltip: this.t(
        "turn_grid_on_off_tooltip",
        {},
        { locale: "de" }
      ),
      turnShiftsOnOffTooltip: this.t(
        "turn_shifts_on_off_tooltip",
        {},
        { locale: "de" }
      ),
      activateGlobalScalingTooltip : this.t(
        "activate_global_scaling_tooltip",
        {},
        { locale: "de" }
      ),
      snapDataOnViewportTooltip : this.t(
        "snap_data_on_viewport_tooltip",
        {},
        { locale: "de" }
      ),
      exitSnapDataOnViewportTooltip : this.t(
        "exit_snap_data_on_viewport_tooltip",
        {},
        { locale: "de" }
      ),
      decreaseChartScaleTooltip: this.t(
        "decrease_chart_scale_tooltip",
        {},
        { locale: "de" }
      ),
      increaseChartScaleTooltip: this.t(
        "increase_chart_scale_tooltip",
        {},
        { locale: "de" }
      ),
      walkLeftOnChartTooltip: this.t(
        "walk_left_on_chart_tooltip",
        {},
        { locale: "de" }
      ),
      walkRightOnChartTooltip: this.t(
        "walk_right_on_chart_tooltip",
        {},
        { locale: "de" }
      ),

      maximumViewport: this.t("maximum_viewport", {}, { locale: "de" }),
      minimumViewport: this.t("minimum_viewport", {}, { locale: "de" }),
      limitAchieved: this.t("limit_achieved", {}, { locale: "de" }),
      timeWindowIndicatorText: this.t("time_window", {}, { locale: "de" }),
      scaleIndicatorText: this.t("scale", {}, { locale: "de" }),
      searchBoxPlaceholder: this.t(
        "search_box_placeholder",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece0Text: this.t(
        "no_datetime_warning_piece_0",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece1Text: this.t(
        "no_datetime_warning_piece_1",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece2Text: this.t(
        "no_datetime_warning_piece_2",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece3Text: this.t(
        "no_datetime_warning_piece_3",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece4Text: this.t(
        "no_datetime_warning_piece_4",
        {},
        { locale: "de" }
      ),
      noDateTimeWarningPiece5Text: this.t(
        "no_datetime_warning_piece_5",
        {},
        { locale: "de" }
      ),

      noMetricsCalculationText: this.t(
        "no_metrics_calculation",
        {},
        { locale: "de" }
      ),
      searchText: this.t("search", {}, { locale: "de" }),

      searchTutorialPieceA0Text: this.t(
        "search_tutorial_piece_A_0",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceA1Text: this.t(
        "search_tutorial_piece_A_1",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceA2Text: this.t(
        "search_tutorial_piece_A_2",
        {},
        { locale: "de" }
      ),

      searchTutorialObservation0Text: this.t(
        "search_tutorial_observation_0",
        {},
        { locale: "de" }
      ),

      searchTutorialPieceB0Text: this.t(
        "search_tutorial_piece_B_0",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceB1Text: this.t(
        "search_tutorial_piece_B_1",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceB2Text: this.t(
        "search_tutorial_piece_B_2",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceB3Text: this.t(
        "search_tutorial_piece_B_3",
        {},
        { locale: "de" }
      ),
      searchTutorialPieceB4Text: this.t(
        "search_tutorial_piece_B_4",
        {},
        { locale: "de" }
      ),

      searchTutorialObservation1Text: this.t(
        "search_tutorial_observation_1",
        {},
        { locale: "de" }
      ),

      viewportText: this.t("viewport", {}, { locale: "de" }),

      viewportDaysText: this.t("viewport_days", {}, { locale: "de" }),

      deliveryReliabilityText: this.t(
        "delivery_reliability",
        {},
        { locale: "de" }
      ),

      inactiveControlsState: true,
      expanded: false,
      deliveryReliabilityPercentage: 0,
      internalShiftButtonActivated: false,
      internalSortedButtonActivated: false,
      internalActivateReset: false,
      resetSearchInputText: false,
      justOneToast: false,
      step: 2,
      interval: false,
      sortByOrder: false,
      // hideTimeaxis: false,
      internalViewportPercentage: 100,
      internalScale: 1,
      walkRightButtonClicked: false,
      walkLeftButtonClicked: false,
      increaseScaleButtonClicked: false,
      decreaseScaleButtonClicked: false,
      restartButtonClicked: false,
      gridButtonClicked: false,
      tagsButtonClicked: false,
      shiftsViewButtonClicked: false,
      globalScaleViewButtonClicked: false,
      snapDataOnViewportButtonClicked: false,
      showGrid: false,
      hideShifts: false,
      viewportTotalDays: 0,
      activateGlobalScaling: false,
      snap: false,
      setClearMode: false,
      clearSearchButtonClicked: false
    };
  },
  components: {
    AxxalonInputText,
    AxxalonBarPercentageIndicator
  },
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },
  mounted() {
    // CALCULATE TOTAL DAYS IN THE VIEWPORT RANGE
    let chartStartDate = moment(this.chartStart.split(" ")[0], 'YYYY-MM-DD')
    let chartEndDate = moment(this.chartEnd.split(" ")[0], 'YYYY-MM-DD')

    this.viewportTotalDays = Math.abs(moment.duration(chartStartDate.diff(chartEndDate)).asDays());
    // ACTIVATE AND DISABLE GLOBAL SCALING BUTTON
    this.emitter.on("setActivateGlobalScaling", state => {
      this.activateGlobalScaling = state;
    })
    this.emitter.on("setGlobalScaleViewButtonClicked", state => {
      this.globalScaleViewButtonClicked = state;
    })

    this.emitter.on("restartCharts", () => {
      this.restartCharts();
    })

    this.emitter.on("setClearMode", state => {
      this.setClearMode = state;
    })

    this.emitter.on("setSortByOrder", state => {
      this.sortByOrder = state;
    })

    // MAKE THE CONTROLS ACTIVE/INACTIVE
    this.emitter.on("disableControlStates", state => {
      this.inactiveControlsState = state;
      // this.inactiveControlsState = !this.inactiveControlsState;
    });

    // TO HAPPEN IN ANY GANTT CONTROL PANEL
    this.emitter.on("clearSearchButtonClicked", state => {
      this.clearSearchButtonClicked = state;
      this.sleep(2500).then(() => {
        // this.setClearMode = true;
        this.emitter.emit("setClearMode", state);
      })
      
    })
    // USED WHEN THE USER CLICKS THE LINK BUTTON, THUS ALTERING IT STATE
    this.emitter.on("linkState", state => {
      if (!state) {
        this.sortByOrder = false;
        this.hideShifts = false;
        this.emitter.emit("setActivateGlobalScaling", false)
        this.emitter.emit("setGlobalScaleViewButtonClicked", false)
        this.sortingFunction(this.sortByOrder);
      }
    });

    this.emitter.on("sortState", state => {
      if (this.linked && state.caller != this.panelCode) {
        this.sortByOrder = state.state;
        // this.sortingFunction(this.sortByOrder);
      }
      // this.sortByOrder = state;
      // this.sortingFunction(this.sortByOrder);
    });

    // USED WHEN THE CONTROLS ARE RESTARTED
    this.emitter.on("restartState", () => {
      if (this.linked) {
        this.sortByOrder = false;
        this.hideShifts = false;
        // this.sortingFunction(this.sortByOrder);
      }
      // this.sortByOrder = false;
      // this.hideShifts = false;
    });

    this.emitter.on("snapButtonState", () => {
      this.snapDataOnViewportButtonClicked = !this.snapDataOnViewportButtonClicked
    })

    this.emitter.on("snapState", () => {
      this.snap = !this.snap;
      
        ////////////////////////////////////
       //  ENABLES/DISABLE RESOURCES PANEL
      ////////////////////////////////////
      // if (this.snap){
      //   this.$emit("rowLabelWidth", 0);
      // }

      // if (!this.snap){
      //   this.$emit("rowLabelWidth", 15);
      // }
    })

    this.deliveryReliabilityPercentage = (
      this.deliveryReliability * 100
    ).toFixed(2);

    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });
    this.configureLanguage();
  },
  beforeUpdate() {
    this.internalScale = this.scale;
    this.internalViewportPercentage = this.viewportPercentage;

    this.configureLanguage();
    
    // CALCULATE TOTAL DAYS IN THE VIEWPORT RANGE
    let chartStartDate = moment(this.chartStart.split(" ")[0], 'YYYY-MM-DD')
    let chartEndDate = moment(this.chartEnd.split(" ")[0], 'YYYY-MM-DD')

    this.viewportTotalDays = Math.abs(moment.duration(chartStartDate.diff(chartEndDate)).asDays());
},
  watch: {
    activateReset: function() {
      this.internalActivateReset = !this.internalActivateReset;
      this.internalShiftButtonActivated = false;
      this.internalSortedButtonActivated = false;
    },
    shiftButtonActivated: function(state) {
      this.internalShiftButtonActivated = state;
    },
    sortedButtonActivated: function(state) {
      this.internalSortedButtonActivated = state;
    }
  },
  methods: {
    configureLanguage() {
      this.localLanguage =
        localStorage.getItem("language") == null
          ? this.$store.state.language
          : localStorage.getItem("language");

      this.unitsExplanationTooltip  = this.t(
        "units_explanation_tooltip",
        {},
        { locale: this.localLanguage }
      );

      this.hideShowResourcesTooltip = this.t(
        "hide_show_resources_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.hideShowTimeAxisTooltip = this.t(
        "hide_show_time_axis_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.sortByOrderTooltip = this.t(
        "sort_by_order_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.expandChartTooltip = this.t(
        "expand_chart_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.linkTopAndBottomChartsTooltip = this.t(
        "link_top_and_bottom_charts_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.restartChartsTooltip = this.t(
        "restart_chart_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.turnGridOnOffTooltip = this.t(
        "turn_grid_on_off_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.turnShiftsOnOffTooltip = this.t(
        "turn_shifts_on_off_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.activateGlobalScalingTooltip  = this.t(
        "activate_global_scaling_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.snapDataOnViewportTooltip  = this.t(
        "snap_data_on_viewport_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.exitSnapDataOnViewportTooltip   = this.t(
        "exit_snap_data_on_viewport_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.decreaseChartScaleTooltip = this.t(
        "decrease_chart_scale_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.increaseChartScaleTooltip = this.t(
        "increase_chart_scale_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.walkLeftOnChartTooltip = this.t(
        "walk_left_on_chart_tooltip",
        {},
        { locale: this.localLanguage }
      );
      this.walkRightOnChartTooltip = this.t(
        "walk_right_on_chart_tooltip",
        {},
        { locale: this.localLanguage }
      );

      this.maximumViewport = this.t(
        "maximum_viewport",
        {},
        { locale: this.localLanguage }
      );
      this.minimumViewport = this.t(
        "minimum_viewport",
        {},
        { locale: this.localLanguage }
      );
      this.limitAchieved = this.t(
        "limit_achieved",
        {},
        { locale: this.localLanguage }
      );

      (this.timeWindowIndicatorText = this.t(
        "time_window",
        {},
        { locale: this.localLanguage }
      )),
        (this.scaleIndicatorText = this.t(
          "scale",
          {},
          { locale: this.localLanguage }
        )),
        (this.searchBoxPlaceholder = this.t(
          "search_box_placeholder",
          {},
          { locale: this.localLanguage }
        ));

      this.noDateTimeWarningPiece0Text = this.t(
        "no_datetime_warning_piece_0",
        {},
        { locale: this.localLanguage }
      );
      this.noDateTimeWarningPiece1Text = this.t(
        "no_datetime_warning_piece_1",
        {},
        { locale: this.localLanguage }
      );
      this.noDateTimeWarningPiece2Text = this.t(
        "no_datetime_warning_piece_2",
        {},
        { locale: this.localLanguage }
      );
      this.noDateTimeWarningPiece3Text = this.t(
        "no_datetime_warning_piece_3",
        {},
        { locale: this.localLanguage }
      );
      this.noDateTimeWarningPiece4Text = this.t(
        "no_datetime_warning_piece_4",
        {},
        { locale: this.localLanguage }
      );
      this.noDateTimeWarningPiece5Text = this.t(
        "no_datetime_warning_piece_5",
        {},
        { locale: this.localLanguage }
      );

      this.noMetricsCalculationText = this.t(
        "no_metrics_calculation",
        {},
        { locale: this.localLanguage }
      );
      this.searchText = this.t("search", {}, { locale: this.localLanguage });

      this.searchTutorialPieceA0Text = this.t(
        "search_tutorial_piece_A_0",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceA1Text = this.t(
        "search_tutorial_piece_A_1",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceA2Text = this.t(
        "search_tutorial_piece_A_2",
        {},
        { locale: this.localLanguage }
      );

      this.searchTutorialObservation0Text = this.t(
        "search_tutorial_observation_0",
        {},
        { locale: this.localLanguage }
      );

      this.searchTutorialPieceB0Text = this.t(
        "search_tutorial_piece_B_0",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceB1Text = this.t(
        "search_tutorial_piece_B_1",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceB2Text = this.t(
        "search_tutorial_piece_B_2",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceB3Text = this.t(
        "search_tutorial_piece_B_3",
        {},
        { locale: this.localLanguage }
      );
      this.searchTutorialPieceB4Text = this.t(
        "search_tutorial_piece_B_4",
        {},
        { locale: this.localLanguage }
      );

      this.searchTutorialObservation1Text = this.t(
        "search_tutorial_observation_1",
        {},
        { locale: this.localLanguage }
      );

      this.viewportText = this.t(
        "viewport",
        {},
        { locale: this.localLanguage }
      );

      this.viewportDaysText = this.t(
        "viewport_days",
        {},
        { locale: this.localLanguage }
      );

      this.deliveryReliabilityText = this.t(
        "delivery_reliability",
        {},
        { locale: this.localLanguage }
      );
    },
    onExpand() {
      this.emitter.emit("setProcessing", null);
      this.timerSleep(2000).then(() => {this.emitter.emit("unsetProcessing", null);})
      this.expanded = !this.expanded;
      this.$emit("expandChart", this.expanded);
    },
    resetSearch() {
      this.resetSearchInputText = !this.resetSearchInputText;
    },
    onClearSearch(){
      // this.setClearMode = true;
      // this.searchButtonClicked = true;
      this.emitter.emit("setProcessing", "search");
      this.emitter.emit("clearSearchButtonClicked", true);
      this.sleep().then(() => {
        this.emitter.emit("setClearMode", true);
        this.$emit("clearSearch");
        this.resetSearch();
      })
    },
    // onChangeSearch(fieldValue) {
    //   if (fieldValue == "" || fieldValue == null) {
    //     this.$emit("clearSearch");
    //     this.resetSearch();
    //   }
    // },
    onSortByOrder() {
      this.sortByOrder = !this.sortByOrder;
      this.emitter.emit("sortState", {
        state: this.sortByOrder,
        caller: this.panelCode
      });

      this.sortingFunction(this.sortByOrder);
    },
    searchGantt(fieldValue) {
      this.emitter.emit("setClearMode", false);
      if (!fieldValue == "" || !fieldValue == null){
        // this.setClearMode = false;
        this.emitter.emit("clearSearchButtonClicked", false);
        // this.clearSearchButtonClicked = false;
        this.searchFunction(fieldValue);
      }
    },
    showUpperSearchInfo(event) {
      this.$refs.upperSearchInfo.toggle(event);
    },
    hideUpperSearchInfo(event) {
      this.$refs.upperSearchInfo.toggle(event);
    },
    changeLink() {
      this.emitter.emit("linkState", this.linked);
      this.$emit("updateLink", this.linked);
    },
    onChangeGridView() {
      this.emitter.emit("setProcessing", null);
      this.timerSleep(2000).then(() => {this.emitter.emit("unsetProcessing", null);})
      this.gridButtonClicked = true;
      this.showGrid = !this.showGrid;
      this.$emit("showHideGrid", this.showGrid);
      this.sleep().then(() => {
        this.gridButtonClicked = false;
      });
    },
    onTags() {
      this.tagsButtonClicked = true;
      this.sleep().then(() => {
        this.tagsButtonClicked = false;
      });
    },
    onChangeShiftsView() {
      this.hideShifts = !this.hideShifts;
      this.shiftsViewButtonClicked = true;
      this.sleep().then(() => {
        this.shiftsViewButtonClicked = false;
      });
      this.shiftFunction(this.hideShifts);
    },
    onChangeGlobalScale() {
      this.emitter.emit("setProcessing", null);
      this.timerSleep(2000).then(() => {this.emitter.emit("unsetProcessing", null);})
      if (this.linked){
        this.emitter.emit("setActivateGlobalScaling", !this.activateGlobalScaling)
        this.emitter.emit("setGlobalScaleViewButtonClicked", this.activateGlobalScaling)
      } else {
        this.activateGlobalScaling = !this.activateGlobalScaling;
        this.globalScaleViewButtonClicked = this.activateGlobalScaling;
      }
      
    },
    onSnapDataOnViewport(){
      this.emitter.emit("snapButtonState")
      this.snapFunction();      
    },
    restartCharts() {
      this.emitter.emit("restartState");

      this.sortByOrder = false;
      this.restartButtonClicked = true;
      this.internalShiftButtonActivated = false;
      this.internalSortedButtonActivated = false;
      this.sleep().then(() => {
        this.restartButtonClicked = false;
      });
      this.$emit("restartViewport");
    },
    walkRightHold() {
      this.walkRightButtonClicked = true;
      this.interval = setInterval(() => {
        // this.walkRightButtonClicked = true;
        this.walkRight();
        if (this.step < 25) {
          if (this.step >= 4) {
            this.step *= 2;
          } else {
            this.step++;
          }
        }
      }, 60);
    },
    walkLeftHold() {
      this.walkLeftButtonClicked = true;
      this.interval = setInterval(() => {
        // this.walkLeftButtonClicked = true;
        this.walkLeft();
        if (this.step < 25) {
          if (this.step >= 4) {
            this.step *= 2;
          } else {
            this.step++;
          }
        }
      }, 60);
    },
    walkRight() {
      // this.walkRightButtonClicked = true;
      // this.sleep().then(() => {
      let originEnd = moment(this.originChartEnd).add(1, "days");

      let currentStart = moment(this.chartStart);
      let currentEnd = moment(this.chartEnd);

      if (currentEnd < originEnd) {
        let computedStart = currentStart
          .add(this.step, "hours")
          .format("YYYY-MM-DD HH:MM");
        let computedEnd = currentEnd
          .add(this.step, "hours")
          .format("YYYY-MM-DD HH:MM");

        computedStart = computedStart.split(":")[0] + ":00";
        computedEnd = computedEnd.split(":")[0] + ":00";

        this.$emit("updateWalk", computedStart, computedEnd);
      } else {
        if (!this.justOneToast) {
          this.justOneToast = true;
          this.$toast
            .add({
              severity: "warn",
              summary: this.limitAchieved,
              detail: "",
              life: 3500
            })
            .then(() => {
              this.justOneToast = false;
            });
        }
      }

      // this.walkRightButtonClicked = false;
      // });
    },
    walkLeft() {
      // this.walkLeftButtonClicked = true;
      // this.sleep().then(() => {
      let originStart = moment(this.originChartStart).subtract(1, "days");

      let currentStart = moment(this.chartStart);
      let currentEnd = moment(this.chartEnd);

      if (currentStart > originStart) {
        let computedStart = currentStart
          .subtract(this.step, "hours")
          .format("YYYY-MM-DD HH:MM");
        let computedEnd = currentEnd
          .subtract(this.step, "hours")
          .format("YYYY-MM-DD HH:MM");

        computedStart = computedStart.split(":")[0] + ":00";
        computedEnd = computedEnd.split(":")[0] + ":00";
        this.$emit("updateWalk", computedStart, computedEnd);
      } else {
        if (!this.justOneToast) {
          this.justOneToast = true;
          this.$toast
            .add({
              severity: "warn",
              summary: this.limitAchieved,
              detail: "",
              life: 3500
            })
            .then(() => {
              this.justOneToast = false;
            });
        }
      }

      // this.walkLeftButtonClicked = false;
      // });
    },

    stop() {
      clearInterval(this.interval);
      this.interval = false;
      this.step = 2;
      // this.$emit("updateWalkPagination", this.chartStart, this.chartEnd);
      this.callPaginationRefresh();
      this.sleep().then(() => {
        this.walkLeftButtonClicked = false;
        this.walkRightButtonClicked = false;
        this.callPaginationRefresh();
      });
    },
    callPaginationRefresh() {
      if (this.panelCode == "UPPER") {
        this.$emit("updateWalkUpperPagination", this.chartStart, this.chartEnd);
        this.$emit(
          "updateWalkLowerPagination",
          this.siblingChartStart,
          this.siblingChartEnd
        );
      } else if (this.panelCode == "LOWER") {
        this.$emit("updateWalkLowerPagination", this.chartStart, this.chartEnd);
        this.$emit(
          "updateWalkUpperPagination",
          this.siblingChartStart,
          this.siblingChartEnd
        );
      }
    },

    increaseScale() {
      this.increaseScaleButtonClicked = true;
      this.sleep().then(() => {
        let start = moment(this.chartStart);
        let end = moment(this.chartEnd);

        let quantityOfDays = -1 * start.diff(end, "days");
        let days = Math.floor(quantityOfDays);

        if (days <= 1 || this.activateGlobalScaling) {
          if (this.scale < 1) {
            this.internalScale += 0.1;
            this.$emit("updateScale", this.internalScale);

            let computedEnd = moment(this.chartEnd).add(1, "days").format("YYYY-MM-DD HH:MM");

            this.$emit("globalViewportUpdate", {end: computedEnd, scale: 0.1})
          } else {
            this.$toast.add({
              severity: "warn",
              summary: this.minimumViewport,
              detail: "",
              life: 3500
            });
          }
        } else {
          if (this.scale < 1) {
            this.internalScale += 0.1;
            this.$emit("updateScale", this.internalScale);
          } else {
            let computedEnd = moment(this.chartEnd)
              .subtract(1, "days")
              .format("YYYY-MM-DD HH:MM");

            let originStart = moment(this.originChartStart);
            let originEnd = moment(this.originChartEnd);

            let quantityOfDaysOrigin = -1 * originStart.diff(originEnd, "days");
            let originDays = Math.floor(quantityOfDaysOrigin);

            let start = moment(this.chartStart);
            let end = moment(this.chartEnd);

            let quantityOfDays = -1 * start.diff(end, "days");
            let days = Math.floor(quantityOfDays);
            this.$emit("updateViewport", computedEnd);

            this.internalViewportPercentage = ((days - 2) / originDays) * 100;
            this.$emit(
              "updateViewportPercentage",
              this.internalViewportPercentage
            );
          }
        }

        this.increaseScaleButtonClicked = false;
      });
    },
    decreaseScale() {
      this.decreaseScaleButtonClicked = true;
      this.sleep().then(() => {
        let originStart = moment(this.originChartStart);
        let originEnd = moment(this.originChartEnd);

        let quantityOfDaysOrigin = -1 * originStart.diff(originEnd, "days");
        let originDays = Math.floor(quantityOfDaysOrigin);

        let currentStart = moment(this.chartStart);
        let currentEnd = moment(this.chartEnd);

        let quantityOfDaysCurrent = -1 * currentStart.diff(currentEnd, "days");
        let currentDays = Math.floor(quantityOfDaysCurrent);

        if (currentDays > originDays || this.activateGlobalScaling) {
          if (this.scale > 0.2) {
            this.internalScale -= 0.1;
            this.$emit("updateScale", this.internalScale);
            
            let computedEnd = moment(this.chartEnd).add(1, "days").format("YYYY-MM-DD HH:MM");
            // let computedStart = moment(this.chartStart).subtract(1, "days").format("YYYY-MM-DD HH:MM");

            this.$emit("globalViewportUpdate", {end: computedEnd, scale: 0.1})

          } else {
            this.$toast.add({
              severity: "warn",
              summary: this.maximumViewport,
              detail: "",
              life: 3500
            });
          }
        } else {
          let computedEnd = moment(this.chartEnd)
            .add(1, "days")
            .format("YYYY-MM-DD HH:MM");

          //   this.$emit("increaseScale", computedEnd);
          this.$emit("updateViewport", computedEnd);

          let currentStart = moment(this.chartStart);
          let currentEnd = moment(this.chartEnd);

          let quantityOfDaysCurrent =
            -1 * currentStart.diff(currentEnd, "days");
          let currentDays = Math.floor(quantityOfDaysCurrent);
          this.internalViewportPercentage = (currentDays / originDays) * 100;
          this.$emit(
            "updateViewportPercentage",
            this.internalViewportPercentage
          );
        }

        this.decreaseScaleButtonClicked = false;
      });
    },
    onRowLabelWidthButtonClick() {
      this.emitter.emit("setProcessing", null);
      this.timerSleep(2000).then(() => {this.emitter.emit("unsetProcessing", null);})
      if (this.rowLabelWidth > 0) {
        this.$emit("rowLabelWidth", 0);
      } else {
        this.$emit("rowLabelWidth", 15);
        this.emitter.emit("restartChartsAfterResizing");
      }
    },
    onHideTimeaxisButtonClick() {
      this.emitter.emit("setProcessing", null);
      this.timerSleep(2000).then(() => {this.emitter.emit("unsetProcessing", null);})
      this.$emit("hideTimeaxis");
    },
    sleep() {
      return new Promise(resolve => {
        setTimeout(resolve, 30);
      });
    },
    timerSleep(time) {
      return new Promise(resolve => {
        setTimeout(resolve, time);
      });
    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    numberFormatThousands(num) {
      return num
        .toFixed(0) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    },
    numberFormatPercentage(num) {
      return (num * 100).toFixed(2);
    },
    currencyFormatDE(num) {
      return (
        num
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
      ); // use . as a separator
    }
  }
};
</script>

<style lang="scss" scoped></style>
