<template>
  <div
    v-show="rawLeadTime > 0 && !snap && !hideResultsTemporarily"
    ref=""
    class="cutted-dual info-box-gray results-panel"
    style="height: 70px;"
  >
    <div class="p-d-flex p-flex-row p-jc-around">
      <div class="p-d-flex p-flex-column p-ai-center">
        <span style="font-size: 15px;"
          ><b class="result-panel-title-fonts">{{
            leadTimeReductionText
          }}</b></span
        >
        <span class="result-panel-percentage-fonts" style="font-size: 25px;"
          ><b class="axxalon-a-red"
            >{{
              numberFormatPercentage((rawLeadTime - optLeadTime) / rawLeadTime)
            }}%</b
          ></span
        >
      </div>
      <div class=" p-d-flex p-flex-column  p-ai-center">
        <span style="font-size: 15px;"
          ><b class="result-panel-second-title-fonts">{{
            avgLeadTimeReductionText
          }}</b></span
        >
        <span
          class="result-panel-second-percentage-fonts"
          style="font-size: 25px;"
          ><b class="axxalon-a-red"
            >{{
              numberFormatPercentage(
                (rawAvLeadTime - optAvLeadTime) / rawAvLeadTime
              )
            }}%</b
          ></span
        >
      </div>
    </div>
  </div>

  <!--   /////////////////////////////// -->
  <!--  // SIMPLIFIED SNAP MODEL         -->
  <!-- ////////////////////////////////  -->
  <div
    v-show="rawLeadTime > 0 && snap && !hideResultsTemporarily"
    ref=""
    class="cutted-dual info-box-gray results-panel"
    style="height: 22px;"
  >
    <div class="p-d-flex p-flex-row p-jc-around">
        <span style="font-size: 13px;"
          ><b class="result-panel-title-fonts">{{ leadTimeReductionText }}:</b>
          <b class="axxalon-a p-ml-4">{{ numberFormatPercentage((rawLeadTime - optLeadTime) / rawLeadTime) }}%</b>
          </span>

        <span style="font-size: 13px;"
          ><b class="result-panel-second-title-fonts">{{ avgLeadTimeReductionText }}:</b>
          <b class="axxalon-a p-ml-4">{{ numberFormatPercentage((rawAvLeadTime - optAvLeadTime) / rawAvLeadTime)}}%</b>
          </span>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import gsap from "gsap";

export default {
  props: {
    snap: { type: Boolean },
    rawLeadTime: { type: Number },
    rawAvLeadTime: { type: Number },
    optLeadTime: { type: Number },
    optAvLeadTime: { type: Number },
    hideResultsTemporarily: {type: Boolean}
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      leadTimeReductionText: this.t(
        "lead_dash_time_reduction",
        {},
        { locale: "de" }
      ),
      avgLeadTimeReductionText: this.t(
        "avg_lead_dash_time_reduction",
        {},
        { locale: "de" }
      )
    };
  },
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },
  mounted() {
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });

    this.configureLanguage();

    // this.animate();
  },
  beforeUpdate() {
    this.configureLanguage();
    // this.animate();
  },
  methods: {
    animate() {
        let tl = gsap.timeline();

        tl.from(".boostCheck", {
          x: 400,
          opacity: 0,
          duration: 1,
          ease: "Expo.easeOutIn"
        });
    },
    configureLanguage() {
      this.localLanguage =
        localStorage.getItem("language") == null
          ? this.$store.state.language
          : localStorage.getItem("language");

      this.leadTimeReductionText = this.t(
        "lead_dash_time_reduction",
        {},
        { locale: this.localLanguage }
      );
      this.avgLeadTimeReductionText = this.t(
        "avg_lead_dash_time_reduction",
        {},
        { locale: this.localLanguage }
      );
    },
    numberFormatPercentage(num) {
      return (num * 100).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped></style>
