<template>
  <div
    class="g-grid-container"
    :style="{
      left: rowLabelWidth,
      width: computeWidth,
      height: maxHeight + 'px'
    }"
  >
    <div
      v-for="(hour, index) in allHours"
      :key="index"
      :class="{
        'g-grid-line': true,
        'g-grid-line-highlighted': highlightedHours.includes(hour)
      }"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "GGanttGrid",

  data() {
    return {
      defaultRowLabelWidth: "100%"
    };
  },
  props: {
    maxHeight: { type: Number },
    chartStart: { type: String },
    chartEnd: { type: String },
    rowLabelWidth: {
      type: String
    },
    highlightedHours: { type: Array, default: () => [] },
    scale: { type: Number }
  },
  methods: {
    // computeWidth() {
    //   if (this.rowLabelWidth != null) {
    //     return 100 - this.rowLabelWidth.replace("%", "") + "%";
    //   }
    //   return this.defaultRowLabelWidth;
    // },
  },
  computed: {
    computeWidth() {
      if (this.rowLabelWidth != null) {
        return 100 - this.rowLabelWidth.replace("%", "") + "%";
      }
      return this.defaultRowLabelWidth;
    },
    allHours() {
      let momentChartStart = moment(this.chartStart);

      let momentChartEnd = moment(this.chartEnd);

      let quantityOfDays =
        -1 * (momentChartStart.diff(momentChartEnd, "days") * (1 / this.scale));
      let days = Math.floor(quantityOfDays);
      let hours = (quantityOfDays % 1) * 24;
      let newEnd = moment(momentChartStart, "DD-MM-YYYY").add(days, "days");
      newEnd.add(hours, "hours");

      momentChartEnd = newEnd;

      let res = [];
      while (momentChartStart.isSameOrBefore(momentChartEnd)) {
        res.push(momentChartStart.hour());
        momentChartStart.add(1, "hour");
      }
      return res;
    }
  }
};
</script>

<style scoped>
.g-grid-container {
  position: absolute;
  top: 0;
  left: 30%; /* must correspond to width of row title */
  width: 70%;
  height: calc(100% - 23px);
  display: flex;
  justify-content: space-between;
}

.g-grid-line {
  width: 1px;
  height: 100%;
  background: #eaeaea;
}

.g-grid-line-highlighted {
  background: #90caf9;
  box-shadow: 0px 0px 0px 1px #90caf9;
}
</style>
