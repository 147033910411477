<template>
  <div class="p-grid p-nogutter nested-grid dashboard" style="height: 100% !important">
    <!-- ########################################## -->
    <!-- ############## FIRST GANTT ############### -->
    <div
      v-show="!lowerExpanded"
      class="upperDashboard p-col-12"
      v-if="rawOperations > 0 || rawLeadTime > 0 || rawAvLeadTime > 0"
    >
      <AxxalonGanttControlPanel
        :panelCode="'UPPER'"
        :panelTitle="panelTitleUpper"
        :panelSubtitle="panelSubtitleUpper"
        :FirstFieldTitle="firstFieldTitleUpper"
        :FirstFieldValueUnit="firstFieldValueUnitUpper"
        :FirstFieldId="'rawOperations'"
        :SecondFieldTitle="secondFieldTitleUpper"
        :SecondFieldValueUnit="secondFieldValueUnitUpper"
        :SecondFieldId="'rawLeadTime'"
        :ThirdFieldTitle="thirdFieldTitleUpper"
        :ThirdFieldValueUnit="thirdFieldValueUnitUpper"
        :ThirdFieldId="'rawAvLeadTime'"
        :originChartStart="originRawChartStart"
        :originChartEnd="originRawChartEnd"
        :chartStart="
          internalChartStartUpper == null
            ? rawChartStart
            : internalChartStartUpper
        "
        :chartEnd="
          internalChartEndUpper == null ? rawChartEnd : internalChartEndUpper
        "
        :siblingChartStart="
          internalChartStartLower == null
            ? optimizedChartStart
            : internalChartStartLower
        "
        :siblingChartEnd="
          internalChartEndLower == null
            ? optimizedChartEnd
            : internalChartEndLower
        "
        :rowLabelWidth="rowLabelWidthUpperDashboard"
        :viewportPercentage="viewportPercentageUpper"
        :scale="scaleUpper"
        :linked="linked"
        :hideTimeaxis="hideTimeaxisUpper"
        :isOptimizationLoaded="isOptimizationLoaded"
        :shiftFunction="
          linked
            ? isOptimizedFileLoaded()
              ? shiftViewInBothGantt
              : shiftViewInRawGantt
            : shiftViewInRawGantt
        "
        :snapFunction="snapViewInBothGantt"
        :searchFunction="
          linked
            ? isOptimizedFileLoaded()
              ? searchInBothChartsGantt
              : searchInRawGantt
            : searchInRawGantt
        "
        :sortingFunction="
          linked
            ? isOptimizedFileLoaded() && linked
              ? sortingInBothGantt
              : sortingInRawGantt
            : sortingInRawGantt
        "
        :activateReset="resetControls"
        :shiftButtonActivated="shiftButtonActivated"
        :sortedButtonActivated="sortedButtonActivated"
        :hideInteractiveControls="rawLeadTime <= 0"
        :deliveryReliability="internalRawDeliveryReliability"
        :searchMode="searchMode"
        @hideTimeaxis="onHideTimeaxisUpperDashboardButtonClick"
        @rowLabelWidth="onRowLabelWidthUpperDashboardButtonClick"
        @updateWalk="onUpdateWalkUpper"
        @updateWalkUpperPagination="onUpdateWalkUpperPagination"
        @updateWalkLowerPagination="onUpdateWalkLowerPagination"
        @updateScale="onUpdateScaleUpper"
        @updateViewport="onUpdateViewportUpper"
        @globalViewportUpdate="onGlobalUpperViewportUpdate"
        @updateLink="onUpdateLink"
        @restartViewport="onRestartViewport"
        @updateViewportPercentage="onUpdateViewportPercentageUpper"
        @clearSearch="onClearRawSearch"
        @showHideGrid="onShowHideUpperGrid"
        @expandChart="onSizingUpperChart"
      />

      <div v-if="rawLeadTime > 0" class="p-col-12">
        <div
          id="upper-chart-card"
          :class="snap ? `p-d-flex no-select no-drag` : `p-d-flex card no-select no-drag`"
          :style="upperChartCardHeight != null ? 'height:' + upperChartCardHeight + 'px;overflow: hidden !important; margin-bottom: 0px;' :
            upperExpanded
              ? 'height: 100vh; overflow: hidden !important; margin-bottom: 0px'
              : 'overflow: hidden !important; margin-bottom: 0px;'
          "
        >
          <g-gantt-chart
            v-if="onlineCharts"
            style="z-index: 0"
            v-bind:ganttCode="'UPPER'"
            :chart-start="
              internalChartStartUpper == null
                ? rawChartStart
                : internalChartStartUpper
            "
            :chart-end="
              internalChartEndUpper == null
                ? rawChartEnd
                : internalChartEndUpper
            "
            :height="upperChartHeight"
            :grid="upperGrid"
            :hide-timeaxis="hideTimeaxisUpperDashboard"
            :push-on-overlap="true"
            snap-back-on-overlap
            :highlighted-hours="highlightedHours"
            :row-label-width="`${rowLabelWidthUpperDashboard}%`"
            :row-height="getRowHeightUpperDashboard"
            :theme="selectedTheme"
            @dragend-bar="onDragend($event)"
            :scale="scaleUpper"
            :numberOfRows="rawDataNumberOfRows"
            :locale="localLanguage"
            :activateScrollPagination="activateScrollPagination"
            :snap="snap"
          >
            <template
              v-for="row in internalRawTreatedData != null
                ? internalRawTreatedData
                : rawTreatedData"
              :key="row.label"
            >
              <g-gantt-row
                :label="row.label"
                :bars="row.barList"
                :highlight-on-hover="highlightOnHover"
                bar-start="start"
                bar-end="end"
                :chartStart="
                  internalChartStartUpper == null
                    ? rawChartStart
                    : internalChartStartUpper
                "
                :chartEnd="
                  internalChartEndUpper == null
                    ? rawChartEnd
                    : internalChartEndUpper
                "
                :scale="scaleUpper"
                :resourcesDisabled="
                  rowLabelWidthUpperDashboard > 0 ? false : true
                "
                :snap="snap"
              >
                <template #bar-label="{bar}">
                  <span>{{ bar.label }}</span>
                </template>
              </g-gantt-row>
            </template>
          </g-gantt-chart>
        </div>
      </div>
    </div>

    <!-- ########################################## -->
    <!-- ############## SECOND GANTT ############### -->
    <div
      v-show="!upperExpanded"
      class="p-grid lower-dashboard p-ml-1"
      style="width: 100%;"
    >
      <template
        id="lowerDashboardID"
        class="lowerDashboard"
        v-if="optOperations > 0 || optLeadTime > 0 || optAvLeadTime > 0"
      >
        <AxxalonGanttControlPanel
          :panelCode="'LOWER'"
          :panelTitle="panelTitleLower"
          :panelSubtitle="panelSubtitleLower"
          :FirstFieldTitle="firstFieldTitleLower"
          :FirstFieldValueUnit="firstFieldValueUnitLower"
          :FirstFieldId="'optOperations'"
          :SecondFieldTitle="secondFieldTitleLower"
          :SecondFieldValueUnit="secondFieldValueUnitLower"
          :SecondFieldId="'optLeadTime'"
          :ThirdFieldTitle="thirdFieldTitleLower"
          :ThirdFieldValueUnit="thirdFieldValueUnitLower"
          :ThirdFieldId="'optAvLeadTime'"
          :originChartStart="originOptimizedChartStart"
          :originChartEnd="originOptimizedChartEnd"
          :chartStart="
            internalChartStartLower == null
              ? optimizedChartStart
              : internalChartStartLower
          "
          :chartEnd="
            internalChartEndLower == null
              ? optimizedChartEnd
              : internalChartEndLower
          "
          :siblingChartStart="
            internalChartStartUpper == null
              ? rawChartStart
              : internalChartStartUpper
          "
          :siblingChartEnd="
            internalChartEndUpper == null ? rawChartEnd : internalChartEndUpper
          "
          :rowLabelWidth="rowLabelWidthLowerDashboard"
          :viewportPercentage="viewportPercentageLower"
          :scale="scaleLower"
          :linked="linked"
          :hideTimeaxis="hideTimeaxisLower"
          :isOptimizationLoaded="isOptimizationLoaded"
          :shiftFunction="
            linked ? shiftViewInBothGantt : shiftViewInOptimizedGantt
          "
          :snapFunction="snapViewInBothGantt"
          :searchFunction="
            linked ? searchInBothChartsGantt : searchInOptimizedGantt
          "
          :sortingFunction="
            linked ? sortingInBothGantt : sortingInOptimizedGantt
          "
          :activateReset="resetControls"
          :sortedButtonActivated="sortedButtonActivated"
          :deliveryReliability="internalOptimizedDeliveryReliability"
          :searchMode="searchMode"
          @hideTimeaxis="onHideTimeaxisLowerDashboardButtonClick"
          @rowLabelWidth="onRowLabelWidthLowerDashboardButtonClick"
          @updateWalk="onUpdateWalkLower"
          @updateScale="onUpdateScaleLower"
          @updateViewport="onUpdateViewportLower"
          @globalViewportUpdate="onGlobalLowerViewportUpdate"
          @updateLink="onUpdateLink"
          @restartViewport="onRestartViewport"
          @updateViewportPercentage="onUpdateViewportPercentageLower"
          @clearSearch="onClearOptimizedSearch"
          @showHideGrid="onShowHideLowerGrid"
          @expandChart="onSizingLowerChart"
        />

        <div class="p-col-4 p-xl-4 p-lg 4 p-md-6" :style="snap? `height: 22px;` :`height: 70px;` ">
          <div class="boostCheck">
            <AxxalonInfoPanel
              v-if="showResults"
              :snap="snap"
              :rawLeadTime="rawLeadTime"
              :rawAvLeadTime="rawAvLeadTime"
              :optLeadTime="optLeadTime"
              :optAvLeadTime="optAvLeadTime"
              :hideResultsTemporarily="hideResultsTemporarily"
            />
          </div>
        </div>

        <div class="p-col-12 p-ml-1" style="padding: 0px;">
          <div
            id="lower-chart-card"
            :class="snap ? `p-d-flex p-flex-column summary no-select no-drag` : `p-d-flex p-flex-column card summary no-select no-drag`"
            :style="lowerChartCardHeight != null ? 'height:' + lowerChartCardHeight + 'px;overflow: hidden !important; margin-bottom: 0px;' :
              lowerExpanded
                ? 'height: 100vh; overflow: hidden !important; margin-bottom: 0px'
                : 'overflow: hidden !important; margin-bottom: 0px;'
            "
          >
            <g-gantt-chart
              v-if="onlineCharts"
              style="z-index: 0"
              :ganttCode="'LOWER'"
              :chart-start="
                internalChartStartLower == null
                  ? optimizedChartStart
                  : internalChartStartLower
              "
              :chart-end="
                internalChartEndLower == null
                  ? optimizedChartEnd
                  : internalChartEndLower
              "
              :height="lowerChartHeight"
              :grid="lowerGrid"
              :hide-timeaxis="hideTimeaxisLowerDashboard"
              :push-on-overlap="true"
              snap-back-on-overlap
              :highlighted-hours="highlightedHours"
              :row-label-width="`${rowLabelWidthLowerDashboard}%`"
              :row-height="getRowHeightLowerDashboard"
              :theme="selectedTheme"
              @dragend-bar="onDragend($event)"
              :scale="scaleLower"
              :numberOfRows="optimizedDataNumberOfRows"
              :locale="localLanguage"
              :activateScrollPagination="activateScrollPagination"
              :snap="snap"
            >
              <template
                v-for="row in internalOptimizedTreatedData != null
                  ? internalOptimizedTreatedData
                  : optimizedTreatedData"
                :key="row.label"
              >
                <g-gantt-row
                  :label="row.label"
                  :bars="row.barList"
                  :highlight-on-hover="highlightOnHover"
                  bar-start="start"
                  bar-end="end"
                  :scale="scaleLower"
                  :defaultRowLabelWidth="rowLabelWidthLowerDashboard"
                  :resourcesDisabled="
                    rowLabelWidthLowerDashboard > 0 ? false : true
                  "
                  :snap="snap"
                >
                  <template #bar-label="{bar}">
                    <span>{{ bar.label }}</span>
                  </template>
                </g-gantt-row>
              </template>
            </g-gantt-chart>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AxxalonInfoPanel from "@/components/Axxalon/AxxalonDashboardControls/AxxalonInfoPanel.vue";
import AxxalonGanttControlPanel from "@/components/Axxalon/AxxalonDashboardControls/AxxalonGanttControlPanel.vue";
// import AxxalonGanttChart from "./AxxalonGantt/AxxalonGanttChart.vue";
// import AxxalonEventManager from "@/components/Axxalon/AxxalonDashboardControls/AxxalonEventManager.vue";
import moment from "moment";
import gsap from "gsap";
import APIService from "../../service/APIService";

import GGanttChart from "./AxxalonGantt/GGanttChart.vue";
import GGanttRow from "./AxxalonGantt/GGanttRow.vue";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  emits: ["updateUpperPagination", "updateLowerPagination"],
  components: {
    GGanttChart,
    GGanttRow,
    // AxxalonEventManager,
    // AxxalonGanttChart,
    AxxalonGanttControlPanel,
    AxxalonInfoPanel
  },
  props: {
    rawOperations: {
      type: Number,
      required: true
    },
    rawLeadTime: {
      type: Number,
      required: true
    },
    rawAvLeadTime: {
      type: Number,
      required: true
    },
    rawDeliveryReliability: { type: Number },

    optOperations: {
      type: Number,
      required: true
    },
    optLeadTime: {
      type: Number,
      required: true
    },
    optAvLeadTime: {
      type: Number,
      required: true
    },
    optimizedDeliveryReliability: { type: Number },

    isOptimizationLoaded: { type: Boolean },

    viewport: { type: Number },
    rawTreatedData: { type: Object },
    optimizedTreatedData: { type: Object },

    rawChartStart: { type: String },
    rawChartEnd: { type: String },
    originRawChartStart: { type: String },
    originRawChartEnd: { type: String },
    initialRawViewportStart: { type: String },
    initialRawViewportEnd: { type: String },

    optimizedChartStart: { type: String },
    optimizedChartEnd: { type: String },

    originOptimizedChartStart: { type: String },
    originOptimizedChartEnd: { type: String },
    initialOptimizedViewportStart: { type: String },
    initialOptimizedViewportEnd: { type: String },

    selectedCSVFileId: { type: String },
    resetDashboardTrigger: { type: Boolean },
    reloadDashboard: { type: Boolean },
    noAnimation: { type: Boolean }
  },
  setup() {
    const store = useStore();

    const { t, locale } = useI18n();

    return { store, t, locale };
  },
  data() {
    return {
      localLanguage: "de",
      panelTitleUpper: this.t("raw", {}, { locale: "de" }),
      panelSubtitleUpper: this.t("data", {}, { locale: "de" }),
      firstFieldTitleUpper: this.t("operations", {}, { locale: "de" }),
      firstFieldValueUnitUpper: this.t("units", {}, { locale: "de" }),
      secondFieldTitleUpper: this.t("lead_time", {}, { locale: "de" }),
      secondFieldValueUnitUpper: this.t("days", {}, { locale: "de" }),
      thirdFieldTitleUpper: this.t("avg_lead_time", {}, { locale: "de" }),
      thirdFieldValueUnitUpper: this.t("days", {}, { locale: "de" }),

      panelTitleLower: this.t("optimized", {}, { locale: "de" }),
      panelSubtitleLower: this.t("data", {}, { locale: "de" }),
      firstFieldTitleLower: this.t("operations", {}, { locale: "de" }),
      firstFieldValueUnitLower: this.t("units", {}, { locale: "de" }),
      secondFieldTitleLower: this.t("lead_time", {}, { locale: "de" }),
      secondFieldValueUnitLower: this.t("days", {}, { locale: "de" }),
      thirdFieldTitleLower: this.t("avg_lead_time", {}, { locale: "de" }),
      thirdFieldValueUnitLower: this.t("days", {}, { locale: "de" }),

      internalNoAnimation: false,

      upperChartHeight: null,
      lowerChartHeight: null,
      upperExpanded: false,
      lowerExpanded: false,
      internalRawDeliveryReliability: 0,
      internalOptimizedDeliveryReliability: 0,
      shiftButtonActivated: false,
      sortedButtonActivated: false,
      globalCounter: 0,
      resetControls: false,
      internalRawTreatedData: null,
      internalOptimizedTreatedData: null,
      controlsClicked: false,
      hideTimeaxisUpper: false,
      hideTimeaxisLower: false,
      linked: true,
      internalChartStartUpper: null,
      internalChartEndUpper: null,
      internalChartStartLower: null,
      internalChartEndLower: null,
      viewportPercentageUpper: 100,
      viewportPercentageLower: 100,
      lowerDashboardSliderValue: [20, 80],
      walkRightLowerDashboardClicked: false,
      walkLeftLowerDashboardClicked: false,
      increaseScaleUpperDashboardClicked: false,
      decreaseScaleUpperDashboardClicked: false,
      increaseScaleLowerDashboardClicked: false,
      decreaseScaleLowerDashboardClicked: false,
      
      scaleUpper: 1,
      scaleLower: 1,

      showResults: false,
      alreadyShowedResults: false,
      CSVLoaded: false,
      ready: false,

      pushOnOverlap: true,

      grid: true, // remover depois
      upperGrid: true,
      lowerGrid: true,

      rowHeight: 40 * 1, // default value
      rowHeightUpperDashboard: 40,
      rowHeightLowerDashboard: 40,

      rowLabelWidth: 15,
      rowLabelWidthUpperDashboard: 15,
      rowLabelWidthLowerDashboard: 15,
      // hideTimeaxis: false, // default value
      hideTimeaxis: false,
      hideTimeaxisUpperDashboard: false,
      hideTimeaxisLowerDashboard: false,
      highlightOnHover: true,
      hours: [...Array(24).keys()],
      highlightedHours: [0],
      showContextmenu: false,
      contextmenuTimeout: null,
      contextmenuX: 0,
      contextmenuY: 0,
      selectedTheme: "default",
      themes: [
        "default",
        "vue",
        "dark",
        "material-blue",
        "creamy",
        "slumber",
        "sky",
        "crimson",
        "grove",
        "fuchsia",
        "flare"
      ],
      activateScrollPagination: true,
      snap: false,
      ganttCalls: [],
      onlineCharts: true,
      upperChartCardHeight: null,
      lowerChartCardHeight: null,
      oldRowLabelWidthUpperDashboardValue: 0,
      oldRowLabelWidthLowerDashboardValue: 0,
      hideResultsTemporarily: false,
      searchMode: false
    };
  },
  watch: {
    rawTreatedData: function() {
      // this.internalOptimizedTreatedData = null;
      this.internalRawTreatedData = null;
    },
    optimizedTreatedData: function() {
      this.internalOptimizedTreatedData = null;
      // this.internalRawTreatedData = null;
    },
    resetDashboardTrigger: function() {
      this.internalOptimizedTreatedData = null;
      this.internalRawTreatedData = null;
    },

    optimizedDeliveryReliability: function(value) {
      this.internalOptimizedDeliveryReliability = value;
    },
    rawDeliveryReliability: function(value) {
      this.internalRawDeliveryReliability = value;
    },
    noAnimation: function(value) {
      this.internalNoAnimation = value;
    },
    snap: function() {
      this.animateUpperDashboard();
      this.animateLowerDashboard();
    }
  },
  created() {},
  unmounted() {
    this.emitter.all.clear("LanguageStateBus");
  },
  updated(){
    this.emitter.on("optimizationLoaded", state => {
      // console.log("RECEIVED optimizationLoaded: " + state)
      if (!state){
        this.emitter.emit("disableControlStates", false);
      } 
    })
  },
  mounted() {
    this.emitter.on("resetDashboard", () => {
      this.internalOptimizedTreatedData = null;
      this.internalRawTreatedData = null; 

    });

    this.emitter.on("updateScaleOnSnap", snapScale => {
      this.scaleUpper = snapScale;
      this.scaleLower = snapScale
    })

    this.emitter.on("turnInternalChartsStartEndNull", () => {
      this.internalChartStartUpper = null;
      this.internalChartEndUpper = null;
      this.internalChartStartLower = null;
      this.internalChartEndLower = null;
    })

    this.emitter.on("restartChartsAfterResizing", () => {
      //get current height
      this.upperChartCardHeight = document.getElementById('upper-chart-card').clientHeight;
      this.lowerChartCardHeight = document.getElementById('lower-chart-card').clientHeight;

      this.onlineCharts = false;
      this.sleep(50).then(() => {
        this.upperChartHeight = null;
        this.lowerChartCardHeight = null;
        this.onlineCharts = true;
      })
    });

    this.emitter.on("restoreChartResourceSideBar", ganttCode => {
      if (ganttCode == "RESET-VIEWPORT"){
        this.ganttCalls = [];
        this.ganttCalls.push(ganttCode);
        return;
      }

      this.ganttCalls.push(ganttCode);
      this.sleep(500).then(() => {
        if (this.ganttCalls.includes("UPPER") && this.ganttCalls.includes("LOWER") && !this.ganttCalls.includes("RESET-VIEWPORT")){
              this.rowLabelWidthUpperDashboard = 15;
              this.rowLabelWidthLowerDashboard = 15;
              if (this.oldRowLabelWidthUpperDashboardValue == 0 && this.oldRowLabelWidthLowerDashboardValue == 0 ){
                this.emitter.emit("restartChartsAfterResizing")
              }

              this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
              this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;  

              this.ganttCalls = []
        }
        if (ganttCode == "UPPER" && !this.isOptimizationLoaded){
          if (this.ganttCalls.includes('RAW-PAGINATION')){
                this.rowLabelWidthUpperDashboard = 15;
                this.rowLabelWidthLowerDashboard = 15;
                if (this.oldRowLabelWidthUpperDashboardValue == 0 && this.oldRowLabelWidthLowerDashboardValue == 0 ){
                  this.emitter.emit("restartChartsAfterResizing")
                }

                this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
                this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard; 

                this.ganttCalls = []
          }  

        } else if (ganttCode == "UPPER" && this.isOptimizationLoaded){
          if (this.ganttCalls.includes('LOWER') && this.ganttCalls.includes('RAW-PAGINATION') && this.ganttCalls.includes('OPTIMIZED-PAGINATION')){
                this.rowLabelWidthUpperDashboard = 15;
                this.rowLabelWidthLowerDashboard = 15;
                if (this.oldRowLabelWidthUpperDashboardValue == 0 && this.oldRowLabelWidthLowerDashboardValue == 0 ){
                  this.emitter.emit("restartChartsAfterResizing")
                }

                this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
                this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard; 

                this.ganttCalls = []
          }
        } else if (ganttCode == "LOWER"){
           if (this.ganttCalls.includes('UPPER') && this.ganttCalls.includes('RAW-PAGINATION') && this.ganttCalls.includes('OPTIMIZED-PAGINATION')){
                  this.rowLabelWidthUpperDashboard = 15;
                  this.rowLabelWidthLowerDashboard = 15;
                  if (this.oldRowLabelWidthUpperDashboardValue == 0 && this.oldRowLabelWidthLowerDashboardValue == 0 ){
                      this.emitter.emit("restartChartsAfterResizing")
                  }

                  this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
                  this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard; 

                  this.ganttCalls = []
           }
        }
        
      });
      
    })

    this.internalNoAnimation = this.noAnimation;

    if (!this.isOptimizedFileLoaded()) {
      this.showResults = false;
    }
    if (
      this.isRawFileLoaded() &&
      !this.isOptimizedFileLoaded() &&
      !this.showResults
    ) {
      this.animateUpperDashboard();
    }

    if (this.isOptimizedFileLoaded() && !this.showResults) {
      this.animateLowerDashboard();
    }

    // if (this.showResults) {
    //   this.animateResults();
    // }
    this.languageBus.on("LanguageStateBus", () => {
      this.$forceUpdate();
    });

    this.configureLanguage();
    this.internalNoAnimation = false;
  },
  beforeUpdate() {
    this.internalNoAnimation = this.noAnimation;

    if (!this.isOptimizedFileLoaded()) {
      this.showResults = false;
    }
    if (
      this.isRawFileLoaded() &&
      !this.isOptimizedFileLoaded() &&
      !this.showResults &&
      !this.controlsClicked
    ) {
      this.animateUpperDashboard();
    }

    if (this.isOptimizedFileLoaded() && !this.showResults) {
      this.animateLowerDashboard();
    }

    this.controlsClicked = false;

    this.configureLanguage();
    this.internalNoAnimation = false;
  },
  computed: {
    rawDataNumberOfRows() {
      if (this.rawTreatedData != null) {
        return this.rawTreatedData.length + 1.8;
      }
      return 0;
    },
    optimizedDataNumberOfRows() {
      if (this.optimizedTreatedData != null) {
        return this.optimizedTreatedData.length + 1.8;
      }
      return 0;
    },
    getRowHeightLowerDashboard() {
      return this.rowHeightLowerDashboard * this.scaleLower;
    },
    getRowHeightUpperDashboard() {
      return this.rowHeightUpperDashboard * this.scaleUpper;
    }
  },
  methods: {
    configureLanguage() {
      this.localLanguage =
        localStorage.getItem("language") == null
          ? this.$store.state.language
          : localStorage.getItem("language");

      this.panelTitleUpper = this.t("raw", {}, { locale: this.localLanguage });
      this.panelSubtitleUpper = this.t(
        "data",
        {},
        { locale: this.localLanguage }
      );
      this.firstFieldTitleUpper = this.t(
        "operations",
        {},
        { locale: this.localLanguage }
      );
      this.firstFieldValueUnitUpper = this.t(
        "units",
        {},
        { locale: this.localLanguage }
      );
      this.secondFieldTitleUpper = this.t(
        "lead_time",
        {},
        { locale: this.localLanguage }
      );
      this.secondFieldValueUnitUpper = this.t(
        "days",
        {},
        { locale: this.localLanguage }
      );
      this.thirdFieldTitleUpper = this.t(
        "avg_lead_time",
        {},
        { locale: this.localLanguage }
      );
      this.thirdFieldValueUnitUpper = this.t(
        "days",
        {},
        { locale: this.localLanguage }
      );

      this.panelTitleLower = this.t(
        "optimized",
        {},
        { locale: this.localLanguage }
      );
      this.panelSubtitleLower = this.t(
        "data",
        {},
        { locale: this.localLanguage }
      );
      this.firstFieldTitleLower = this.t(
        "operations",
        {},
        { locale: this.localLanguage }
      );
      this.firstFieldValueUnitLower = this.t(
        "units",
        {},
        { locale: this.localLanguage }
      );
      this.secondFieldTitleLower = this.t(
        "lead_time",
        {},
        { locale: this.localLanguage }
      );
      this.secondFieldValueUnitLower = this.t(
        "days",
        {},
        { locale: this.localLanguage }
      );
      this.thirdFieldTitleLower = this.t(
        "avg_lead_time",
        {},
        { locale: this.localLanguage }
      );
      this.thirdFieldValueUnitLower = this.t(
        "days",
        {},
        { locale: this.localLanguage }
      );
    },
    onSizingUpperChart(state) {
      if (state) {
        this.onExpandUpperChart();
      } else {
        this.onContractUpperChart();
      }
    },
    onExpandUpperChart() {
      this.upperChartHeight = "100%";
      this.upperExpanded = true;
      this.lowerExpanded = false;
      if (!this.searchMode){
        this.emitter.emit("updateUpperExpandedVerticalPagination", true);
      }
    },
    onContractUpperChart() {
      this.upperChartHeight = null;
      this.upperExpanded = false;
      if (!this.searchMode){
        this.emitter.emit("updateUpperExpandedVerticalPagination", false);
      }
    },
    onSizingLowerChart(state) {
      if (state) {
        this.onExpandLowerChart();
      } else {
        this.onContractLowerChart();
      }
    },
    onExpandLowerChart() {
      this.lowerChartHeight = "100%";
      this.lowerExpanded = true;
      this.upperExpanded = false;
      if (!this.searchMode){
        this.emitter.emit("updateLowerExpandedVerticalPagination", true);
      } 
    },
    onContractLowerChart() {
      this.lowerChartHeight = null;
      this.lowerExpanded = false;
      if (!this.searchMode){
        this.emitter.emit("updateLowerExpandedVerticalPagination", false);
      }
    },
    onShowHideUpperGrid(state) {
      this.upperGrid = !state;
      if (this.linked) {
        this.lowerGrid = !state;
      }
    },
    onShowHideLowerGrid(state) {
      this.lowerGrid = !state;
      if (this.linked) {
        this.upperGrid = !state;
      }
    },
    updateRawDataGantt(result) {
      this.internalRawTreatedData = result;
    },

      ////////////////////////////////
     // SHIFT CODE FUNCTIONS
    /////////////////////////////////
    shiftViewInRawGantt(state) {
      this.emitter.emit("setProcessing", "shift");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .hideShiftInRawGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.internalRawTreatedData = result.data.treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api
          .showShiftInRawGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.internalRawTreatedData = result.data.treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },
    shiftViewInOptimizedGantt(state) {
      this.emitter.emit("setProcessing", "shift");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .hideShiftInOptimizedGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.internalOptimizedTreatedData = result.data.treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api
          .showShiftInOptimizedGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.internalOptimizedTreatedData = result.data.treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },
    shiftViewInBothGantt(state) {
      this.emitter.emit("setProcessing", "shift");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .hideShiftInBothGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              // this.shiftButtonActivated = true;

              this.internalRawTreatedData =
                result.data.rawTreatedData.treatedList;
              this.internalOptimizedTreatedData =
                result.data.optimizedTreatedData.treatedList;

              // this.internalRawTreatedData = JSON.parse(
              //   result.data.treatedRawList
              // ).treatedList;

              // this.internalOptimizedTreatedData = JSON.parse(
              //   result.data.treatedOptimizedList
              // ).treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api
          .showShiftInBothGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              // this.shiftButtonActivated = true;

              this.internalRawTreatedData =
                result.data.rawTreatedData.treatedList;
              this.internalOptimizedTreatedData =
                result.data.optimizedTreatedData.treatedList;

              // this.internalRawTreatedData = JSON.parse(
              //   result.data.treatedRawList
              // ).treatedList;
              // this.internalOptimizedTreatedData = JSON.parse(
              //   result.data.treatedOptimizedList
              // ).treatedList;
            }
            this.emitter.emit("unsetProcessing", "shift");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },

      ////////////////////////////////
     // SNAP DATA CODE FUNCTIONS
    /////////////////////////////////
    snapViewInBothGantt() {
      if (!this.snap){
        this.activateScrollPagination = false;  
      } else {
        this.activateScrollPagination = true;
      }
      // this.activateScrollPagination = !this.activateScrollPagination;
      
      this.emitter.emit("setProcessing", "snap");
      this.emitter.emit("disableControlStates", true);
      this.emitter.emit("setSortByOrder", false);
      if (!this.snap){
        APIService.api
          .snapDataInBothGantt(this.selectedCSVFileId, !this.snap)
          .then(result => {
            if (result != null) {
                  this.hideResultsTemporarily = true;
                  this.emitter.emit("setBothChartData", result)   
                  this.snap = !this.snap;
                  this.emitter.emit("snapState", this.snap)
                  this.rowLabelWidthUpperDashboard = 0;
                  this.rowLabelWidthLowerDashboard = 0;  
                  this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
                  this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;        
            }
            this.emitter.emit("unsetProcessing", "snap");
            this.emitter.emit("disableControlStates", false);

          }).catch(() => {
            this.emitter.emit("unsetProcessing", "snap");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api.usortBothGantt(this.selectedCSVFileId).then(result => {
          if (result != null) {
            this.sortedButtonActivated = false;
            this.hideResultsTemporarily = true;
            this.emitter.emit("restoreBackupViewport") 
            // this.emitter.emit("setBothChartData", null) // TODO check if is necessary

            // console.log("RESULT NO UN-SNAP")
            // console.log(result)

            this.internalRawTreatedData = JSON.parse(
              result.data.treatedRawList
            ).treatedList;

            this.internalOptimizedTreatedData = JSON.parse(
              result.data.treatedOptimizedList
            ).treatedList;
 
            this.emitter.emit("unsetProcessing", "snap");
            this.emitter.emit("disableControlStates", false);
            this.snap = !this.snap;
            this.emitter.emit("snapState", this.snap)
            this.emitter.emit("restoreChartResourceSideBar", "UPPER")
            this.emitter.emit("restoreChartResourceSideBar", "LOWER") 
          }
          this.sleep(2000).then(() => {
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
        });
        // .then(() => {
        //     this.rowLabelWidthUpperDashboard = 15;
        //     this.rowLabelWidthLowerDashboard = 15;
        // });
      }
      
    },

      ////////////////////////////////
     // SORTING CODE FUNCTIONS
    /////////////////////////////////
    sortingInRawGantt(state) {
      this.emitter.emit("setProcessing", "sort");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .sortingInRawGantt(this.selectedCSVFileId, "productionOrder")
          .then(result => {
            if (result != null) {
              this.internalRawTreatedData = result.data;
            }
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api.unsortRawGantt(this.selectedCSVFileId).then(result => {
          if (result != null) {
            this.internalRawTreatedData = result.data.treatedList;
          }
          this.emitter.emit("unsetProcessing", "sort");
          this.emitter.emit("disableControlStates", false);
        });
      }
    },
    sortingInOptimizedGantt(state) {
      this.emitter.emit("setProcessing", "sort");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .sortingInOptimizedGantt(this.selectedCSVFileId, "productionOrder")
          .then(result => {
            if (result != null) {
              this.internalOptimizedTreatedData = result.data;
            }
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api
          .usortOptimizedGantt(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.internalOptimizedTreatedData = result.data.treatedList;
            }
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },
    sortingInBothGantt(state) {
      this.emitter.emit("setProcessing", "sort");
      this.emitter.emit("disableControlStates", true);

      if (state) {
        APIService.api
          .sortingInBothGantt(this.selectedCSVFileId, "productionOrder")
          .then(result => {
            if (result != null) {
              this.sortedButtonActivated = true;
              this.internalRawTreatedData = JSON.parse(
                result.data.treatedRawList
              );
              this.internalOptimizedTreatedData = JSON.parse(
                result.data.treatedOptimizedList
              );
            }
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
      } else {
        APIService.api.usortBothGantt(this.selectedCSVFileId).then(result => {
          if (result != null) {
            this.sortedButtonActivated = false;

            this.internalRawTreatedData = JSON.parse(
              result.data.treatedRawList
            ).treatedList;
            this.internalOptimizedTreatedData = JSON.parse(
              result.data.treatedOptimizedList
            ).treatedList;
          }
          this.sleep(2000).then(() => {
            this.emitter.emit("unsetProcessing", "sort");
            this.emitter.emit("disableControlStates", false);
          });
        });
      }
    },

    sleep(amount) {
      return new Promise(resolve => {
        setTimeout(resolve, amount);
      });
    },

      ////////////////////////////////
     // CLEAR SEARCH CODE FUNCTIONS
    /////////////////////////////////
    onClearRawSearch() {
      this.emitter.emit("setProcessing", "search");
      this.emitter.emit("disableControlStates", true);

      APIService.api
        .clearRawSearchTreatedData(this.selectedCSVFileId)
        .then(result => {
          if (result != null) {
            this.searchMode = false;
            this.emitter.emit("setSearchMode", this.searchMode);
            this.activateScrollPagination = true;

            this.internalRawTreatedData = result.data.treatedList;

            
            // console.log("NO CLEAR DO RAW:")
            // console.log("result.data.chartStartDate: " + result.data.chartStartDate)
            // console.log("result.data.chartEndDate: " + result.data.chartEndDate)
            this.setUpperViewport(
              result.data.chartStartDate,
              result.data.chartEndDate
            );

            let newRawTop = result.data.topViewport;
            let newRawBottom = result.data.bottomViewport;
            let newRawChartStartDate = result.data.chartStartDate;
            let newRawChartEndDate = result.data.chartEndDate;
            this.emitter.emit("updateRawVerticalHorizontalViewport", {
              rawTop: newRawTop,
              rawBottom: newRawBottom,
              rawChartStartDate: newRawChartStartDate,
              rawChartEndDate: newRawChartEndDate
            });
          }
          this.emitter.emit("unsetProcessing", "search");
          this.emitter.emit("disableControlStates", false);
        });
      if (this.linked) {
        APIService.api
          .clearOptimizedSearchTreatedData(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.searchMode = false;
              this.emitter.emit("setSearchMode", this.searchMode);
              this.activateScrollPagination = true;

              this.internalOptimizedTreatedData = result.data.treatedList;

              this.setLowerViewport(
                result.data.chartStartDate,
                result.data.chartEndDate
              );

              let newOptimizedTop = result.data.topViewport;
              let newOptimizedBottom = result.data.bottomViewport;
              let newOptimizedChartStartDate = result.data.chartStartDate;
              let newOptimizedChartEndDate = result.data.chartEndDate;
              this.emitter.emit("updateOptimizedVerticalHorizontalViewport", {
                optimizedTop: newOptimizedTop,
                optimizedBottom: newOptimizedBottom,
                optimizedChartStartDate: newOptimizedChartStartDate,
                optimizedChartEndDate: newOptimizedChartEndDate
              });
            }
            this.emitter.emit("unsetProcessing", "search");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },
    onClearOptimizedSearch() {
      this.emitter.emit("setProcessing", "search");
      this.emitter.emit("disableControlStates", true);

      APIService.api
        .clearOptimizedSearchTreatedData(this.selectedCSVFileId)
        .then(result => {
          if (result != null) {
            this.searchMode = false;
            this.emitter.emit("setSearchMode", this.searchMode);
            this.activateScrollPagination = true;
            this.internalOptimizedTreatedData = result.data.treatedList;

            // this.setUpperViewport(null, null);
            this.setUpperViewport(
              result.data.chartStartDate,
              result.data.chartEndDate
            );

            let newOptimizedTop = result.data.topViewport;
            let newOptimizedBottom = result.data.bottomViewport;
            let newOptimizedChartStartDate = result.data.chartStartDate;
            let newOptimizedChartEndDate = result.data.chartEndDate;
            this.emitter.emit("updateOptimizedVerticalHorizontalViewport", {
              optimizedTop: newOptimizedTop,
              optimizedBottom: newOptimizedBottom,
              optimizedChartStartDate: newOptimizedChartStartDate,
              optimizedChartEndDate: newOptimizedChartEndDate
            });
          }
          this.emitter.emit("unsetProcessing", "search");
          this.emitter.emit("disableControlStates", false);
        });
      if (this.linked) {
        APIService.api
          .clearRawSearchTreatedData(this.selectedCSVFileId)
          .then(result => {
            if (result != null) {
              this.searchMode = false;
              this.emitter.emit("setSearchMode", this.searchMode);
              this.activateScrollPagination = true;
              this.internalRawTreatedData = result.data.treatedList;
              // this.setLowerViewport(null, null);
              this.setLowerViewport(
                result.data.chartStartDate,
                result.data.chartEndDate
              );

            let newRawTop = result.data.topViewport;
            let newRawBottom = result.data.bottomViewport;
            let newRawChartStartDate = result.data.chartStartDate;
            let newRawChartEndDate = result.data.chartEndDate;
            this.emitter.emit("updateRawVerticalHorizontalViewport", {
              rawTop: newRawTop,
              rawBottom: newRawBottom,
              rawChartStartDate: newRawChartStartDate,
              rawChartEndDate: newRawChartEndDate
            });
            }
            this.emitter.emit("unsetProcessing", "search");
            this.emitter.emit("disableControlStates", false);
          });
      }
    },
    updateOptimizedDataGantt(result) {
      this.internalOptimizedTreatedData = result;
    },

      ////////////////////////////////
     // SEARCH CODE FUNCTIONS
    /////////////////////////////////
    searchInRawGantt(valueToSearch) {
      this.emitter.emit("setProcessing", "search");
      this.emitter.emit("disableControlStates", true);
      APIService.api
        .searchInRawGantt(this.selectedCSVFileId, valueToSearch)
        .then(result => {
          if (result != null) {
            this.searchMode = true;
            this.emitter.emit("setSearchMode", this.searchMode);
            this.activateScrollPagination = false;

            this.internalRawTreatedData = result.data.treatedList;

            this.setUpperViewport(
              result.data.chartStartDate,
              result.data.chartEndDate
            );

            let newRawTop = result.data.topViewport;
            let newRawBottom = result.data.bottomViewport;
            let newRawChartStartDate = result.data.chartStartDate;
            let newRawChartEndDate = result.data.chartEndDate;

            this.emitter.emit("updateRawVerticalHorizontalViewport", {
              rawTop: newRawTop,
              rawBottom: newRawBottom,
              rawChartStartDate: newRawChartStartDate,
              rawChartEndDate: newRawChartEndDate
            });

            this.emitter.emit("unsetProcessing", "search");
            this.emitter.emit("resetScrollPosition");
          }
          this.emitter.emit("disableControlStates", false);
          this.emitter.emit("unsetProcessing", "search");
        });
    },
    searchInOptimizedGantt(valueToSearch) {
      this.emitter.emit("setProcessing", "search");
      this.emitter.emit("disableControlStates", true);

      APIService.api
        .searchInOptimizedGantt(this.selectedCSVFileId, valueToSearch)
        .then(result => {
          if (result != null) {
            this.searchMode = true;
            this.emitter.emit("setSearchMode", this.searchMode);
            this.activateScrollPagination = false;

            this.internalOptimizedTreatedData = result.data.treatedList;
            
            this.setLowerViewport(
              result.data.chartStartDate,
              result.data.chartEndDate
              // JSON.parse(result.data.treatedList).chartStartDate,
              // JSON.parse(result.data.treatedList).chartEndDate
            );

            // let newOptimizedTop = JSON.parse(result.data.treatedList)
            //   .topViewport;
            // let newOptimizedBottom = JSON.parse(result.data.treatedList)
            //   .bottomViewport;
            // let newOptimizedChartStartDate = JSON.parse(result.data.treatedList)
            //   .chartStartDate;
            // let newOptimizedChartEndDate = JSON.parse(result.data.treatedList)
            //   .chartEndDate;

            let newOptimizedTop = result.data.topViewport;
            let newOptimizedBottom = result.data.bottomViewport;
            let newOptimizedChartStartDate = result.data.chartStartDate;
            let newOptimizedChartEndDate = result.data.chartEndDate;

            this.emitter.emit("updateOptimizedVerticalHorizontalViewport", {
              optimizedTop: newOptimizedTop,
              optimizedBottom: newOptimizedBottom,
              optimizedChartStartDate: newOptimizedChartStartDate,
              optimizedChartEndDate: newOptimizedChartEndDate
            });
            this.emitter.emit("unsetProcessing", "search");
            this.emitter.emit("resetScrollPosition");
          }
          this.emitter.emit("unsetProcessing", "search");
          this.emitter.emit("disableControlStates", false);
        });
    },
    searchInBothChartsGantt(valueToSearch) {
      this.emitter.emit("disableControlStates", true);

      this.emitter.emit("setProcessing", "search");
      APIService.api
        .searchInBothGantt(this.selectedCSVFileId, valueToSearch)
        .then(result => {
          if (result != null) {
            this.searchMode = true;
            this.emitter.emit("setSearchMode", this.searchMode);
            this.activateScrollPagination = false;

            this.internalRawTreatedData = JSON.parse(
              result.data.treatedRawList
            ).treatedList;
            this.internalOptimizedTreatedData = JSON.parse(
              result.data.treatedOptimizedList
            ).treatedList;


            // console.log("new raw internal data: ")
            // console.log(this.internalRawTreatedData)
            // console.log("new optimized internal data: ")
            // console.log(this.internalOptimizedTreatedData)

            this.setUpperViewport(
              JSON.parse(result.data.treatedRawList).chartStartDate,
              JSON.parse(result.data.treatedRawList).chartEndDate
            );

            this.setLowerViewport(
              JSON.parse(result.data.treatedOptimizedList).chartStartDate,
              JSON.parse(result.data.treatedOptimizedList).chartEndDate
            );

            // console.log("new raw top: " + JSON.parse(result.data.treatedRawList).topViewport)
            // console.log("new raw bottom: " + JSON.parse(result.data.treatedRawList).bottomViewport)

            // console.log("new optimized top: " + JSON.parse(result.data.treatedOptimizedList).topViewport)
            // console.log("new optimized bottom: " + JSON.parse(result.data.treatedOptimizedList).bottomViewport)

            let newRawTop = JSON.parse(result.data.treatedRawList).topViewport;
            let newRawBottom = JSON.parse(result.data.treatedRawList)
              .bottomViewport;
            let newRawChartStartDate = JSON.parse(result.data.treatedRawList)
              .chartStartDate;
            let newRawChartEndDate = JSON.parse(result.data.treatedRawList)
              .chartEndDate;

            let newOptimizedTop = JSON.parse(result.data.treatedOptimizedList)
              .topViewport;
            let newOptimizedBottom = JSON.parse(
              result.data.treatedOptimizedList
            ).bottomViewport;
            let newOptimizedChartStartDate = JSON.parse(
              result.data.treatedOptimizedList
            ).chartStartDate;
            let newOptimizedChartEndDate = JSON.parse(
              result.data.treatedOptimizedList
            ).chartEndDate;
            this.emitter.emit("updateBothVerticalHorizontalViewport", {
              rawTop: newRawTop,
              rawBottom: newRawBottom,
              rawChartStartDate: newRawChartStartDate,
              rawChartEndDate: newRawChartEndDate,
              optimizedTop: newOptimizedTop,
              optimizedBottom: newOptimizedBottom,
              optimizedChartStartDate: newOptimizedChartStartDate,
              optimizedChartEndDate: newOptimizedChartEndDate
            });
            this.emitter.emit("unsetProcessing", "search");
            this.emitter.emit("resetScrollPosition");
          }
          this.emitter.emit("unsetProcessing", "search");
          this.emitter.emit("disableControlStates", false);
        });
    },


      ////////////////////////////////
     // VIEWPORT HANDLING
    /////////////////////////////////
    setUpperViewport(start, end) {
      if (start == null) {
        this.internalChartStartUpper = null;
      } else {
        this.internalChartStartUpper =
          moment(start.split(" ")[0])
            .format("YYYY-MM-DD HH:MM")
            .split(" ")[0] + " 00:00";
      }
      if (end == null) {
        this.internalChartEndUpper = null;
      } else {
        this.internalChartEndUpper =
          moment(end.split(" ")[0])
            // .add(1, "days")
            .format("YYYY-MM-DD HH:MM")
            .split(" ")[0] + " 00:00";
      }
    },
    setLowerViewport(start, end) {
      if (start == null) {
        this.internalChartStartLower = null;
      } else {
        this.internalChartStartLower =
          moment(start.split(" ")[0])
            .format("YYYY-MM-DD HH:MM")
            .split(" ")[0] + " 00:00";
      }
      if (end == null) {
        this.internalChartEndLower = null;
      } else {
        this.internalChartEndLower =
          moment(end.split(" ")[0])
            // .add(1, "days")
            .format("YYYY-MM-DD HH:MM")
            .split(" ")[0] + " 00:00";
      }
    },

    onRestartViewport() {
      this.emitter.emit("disableControlStates", true);
      this.emitter.emit("restoreChartResourceSideBar", "RESET-VIEWPORT")

      this.controlsClicked = true;

      // this.sleep(2000).then(() => {
      //   this.rowLabelWidthUpperDashboard = 0;
      //   this.rowLabelWidthLowerDashboard = 0;
      // });

      this.setUpperViewport(
        this.initialRawViewportStart,
        this.initialRawViewportEnd
      );

      this.setLowerViewport(
        this.initialOptimizedViewportStart,
        this.initialOptimizedViewportEnd
      );

      this.onUpdateWalkUpperPagination(
        this.initialRawViewportStart,
        this.initialRawViewportEnd,
        this.topViewport,
        this.bottomViewport,
        true,
        false,
        true
      );

      this.onUpdateWalkLowerPagination(
        this.initialRawViewportStart,
        this.initialRawViewportEnd,
        this.topViewport,
        this.bottomViewport,
        true,
        false,
        true
      );

      this.onUpdateWalkLowerPagination(
        this.initialRawViewportStart,
        this.initialRawViewportEnd,
        this.topViewport,
        this.bottomViewport,
        true,
        false,
        true
      );

      // this.$emit(
      //   "updateWalkUpperPagination",
      //   this.initialRawViewportStart,
      //   this.initialRawViewportEnd,
      //   this.topViewport,
      //   this.bottomViewport,
      //   false,
      //   false,
      //   true
      // );

      // this.$emit(
      //   "updateWalkLowerPagination",
      //   this.initialOptimizedViewportStart,
      //   this.initialOptimizedViewportEnd,
      //   this.topViewport,
      //   this.bottomViewport,
      //   false,
      //   false,
      //   true
      // );

      this.scaleUpper = 1;
      this.scaleLower = 1;
      this.viewportPercentageUpper = 100;
      this.viewportPercentageLower = 100;
      this.rowLabelWidthUpperDashboard = 15;
      this.rowLabelWidthLowerDashboard = 15;

      this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
      this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;

      this.hideTimeaxisUpper = false;
      this.hideTimeaxisLower = false;
      this.hideTimeaxisUpperDashboard = false;
      this.hideTimeaxisLowerDashboard = false;

      this.internalRawTreatedData = null;
      this.internalOptimizedTreatedData = null;

      this.resetControls = !this.resetControls;
      this.shiftButtonActivated = false;
      this.sortedButtonActivated = false;

      this.upperGrid = true;
      this.lowerGrid = true;

      this.sleep(2000).then(() => {
        this.emitter.emit("disableControlStates", false);
      });
    },

      ////////////////////////////////
     // LINKING FUNCTIONS
    /////////////////////////////////
    onUpdateLink() {
      this.emitter.emit("disableControlStates", true);

      this.controlsClicked = true;

      this.linked = !this.linked;
      if (this.linked) {
        // this.setUpperViewport(this.originRawChartStart, this.originRawChartEnd);

        this.setUpperViewport(
          this.initialRawViewportStart,
          this.initialRawViewportEnd
        );

        this.setLowerViewport(
          this.initialRawViewportStart,
          this.initialRawViewportEnd
        );

        this.onUpdateWalkUpperPagination(
          this.initialRawViewportStart,
          this.initialRawViewportEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          false,
          true
        );

        this.onUpdateWalkLowerPagination(
          this.initialRawViewportStart,
          this.initialRawViewportEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          false,
          true
        );

        // this.$emit(
        //   "updateWalkUpperPagination",
        //   this.initialRawViewportStart,
        //   this.initialRawViewportEnd
        // );

        // this.$emit(
        //   "updateWalkLowerPagination",
        //   this.initialRawViewportStart,
        //   this.initialRawViewportEnd
        // );

        // this.setLowerViewport(
        // this.originOptimizedChartStart,
        // this.originOptimizedChartEnd
        // );

        this.scaleUpper = 1;
        this.scaleLower = 1;
        this.viewportPercentageUpper = 100;
        this.viewportPercentageLower = 100;
        this.rowLabelWidthUpperDashboard = 15;
        this.rowLabelWidthLowerDashboard = 15;

        this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
        this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;

        this.hideTimeaxisUpper = false;
        this.hideTimeaxisLower = false;
        this.hideTimeaxisUpperDashboard = false;
        this.hideTimeaxisLowerDashboard = false;

        this.internalRawTreatedData = null;
        this.internalOptimizedTreatedData = null;

        this.resetControls = !this.resetControls;

        this.shiftButtonActivated = false;
        this.sortedButtonActivated = false;

        this.upperGrid = true;
        this.lowerGrid = true;
      }

      this.sleep(2000).then(() => {
        this.emitter.emit("disableControlStates", false);
      });
    },
    onUpdateViewportPercentageUpper(viewportPercentage) {
      this.controlsClicked = true;
      if (this.linked) {
        this.viewportPercentageUpper = viewportPercentage;
        this.viewportPercentageLower = viewportPercentage;
      } else {
        this.viewportPercentageUpper = viewportPercentage;
      }
    },
    onUpdateViewportPercentageLower(viewportPercentage) {
      this.controlsClicked = true;
      if (this.linked) {
        this.viewportPercentageLower = viewportPercentage;
        this.viewportPercentageUpper = viewportPercentage;
      } else {
        this.viewportPercentageLower = viewportPercentage;
      }
    },
    onUpdateWalkUpperPagination(
      newStart,
      newEnd,
      top,
      bottom,
      verticalActivated,
      expanded,
      firstLoad
    ) {
      this.$emit(
        "updateUpperPagination",
        newStart,
        newEnd,
        top,
        bottom,
        verticalActivated,
        expanded,
        firstLoad
      );
    },
    onUpdateWalkLowerPagination(
      newStart,
      newEnd,
      top,
      bottom,
      verticalActivated,
      expanded,
      firstLoad
    ) {
      this.$emit(
        "updateLowerPagination",
        newStart,
        newEnd,
        top,
        bottom,
        verticalActivated,
        expanded,
        firstLoad
      );
    },
    onUpdateWalkUpper(newStart, newEnd) {
      this.controlsClicked = true;
      if (this.linked) {
        // if (
        //   moment(newEnd).isAfter(moment(this.internalChartEndLower)) ||
        //   moment(newEnd).isAfter(moment(this.internalChartEndUpper)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartLower)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartUpper))
        // ) {
        //   this.$emit("updateUpperPagination", newStart, newEnd);
        // }
        this.internalChartStartUpper = newStart;
        this.internalChartEndUpper = newEnd;
        this.internalChartStartLower = newStart;
        this.internalChartEndLower = newEnd;
        this.$emit("updateUpperPagination", newStart, newEnd);
        this.$emit("updateLowerPagination", newStart, newEnd);
      } else {
        // if (
        //   moment(newEnd).isAfter(moment(this.internalChartEndUpper)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartUpper))
        // ) {
        //   this.$emit("updateUpperPagination", newStart, newEnd);
        // }
        this.internalChartStartUpper = newStart;
        this.internalChartEndUpper = newEnd;
        this.$emit("updateUpperPagination", newStart, newEnd);
      }
    },
    onUpdateWalkLower(newStart, newEnd) {
      this.controlsClicked = true;
      if (this.linked) {
        // if (
        //   moment(newEnd).isAfter(moment(this.internalChartEndLower)) ||
        //   moment(newEnd).isAfter(moment(this.internalChartEndUpper)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartLower)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartUpper))
        // ) {
        //   this.$emit("updateLowerPagination", newStart, newEnd);
        // }
        this.internalChartStartLower = newStart;
        this.internalChartEndLower = newEnd;
        this.internalChartStartUpper = newStart;
        this.internalChartEndUpper = newEnd;
        this.$emit("updateUpperPagination", newStart, newEnd);
        this.$emit("updateLowerPagination", newStart, newEnd);
      } else {
        // if (
        //   moment(newEnd).isAfter(moment(this.internalChartEndLower)) ||
        //   moment(newStart).isBefore(moment(this.internalChartStartLower))
        // ) {
        //   this.$emit("updateLowerPagination", newStart, newEnd);
        // }
        this.internalChartStartLower = newStart;
        this.internalChartEndLower = newEnd;
        this.$emit("updateLowerPagination", newStart, newEnd);
      }
    },

    onGlobalUpperViewportUpdate(params) {
      // console.log("GLOBAL VIEWPORT UPDATE UPPER")
      this.controlsClicked = true;
      if (this.linked) {
        this.internalChartEndUpper = params.end;
        this.internalChartEndLower = params.end;

        let newEnd = params.end;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper == null
          ? this.rawChartStart
          : this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower == null
            ? this.optimizedChartStart
            : this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );
      } else {
        this.internalChartEndUpper = params.end;

        let newEnd = params.end;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper == null
            ? this.rawChartStart
            : this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );
      }
    },

    onGlobalLowerViewportUpdate(params) {
      // console.log("GLOBAL VIEWPORT UPDATE LOWER")
      this.controlsClicked = true;
      if (this.linked) {
        this.internalChartEndUpper = params.end;
        this.internalChartEndLower = params.end;

        let newEnd = params.end;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper == null
            ? this.rawChartStart
            : this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower == null
            ? this.optimizedChartStart
            : this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );
      } else {
        this.internalChartEndLower = params.end;

        let newEnd = params.end;

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower == null
            ? this.optimizedChartStart
            : this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          false,
          this.expanded,
          false
        );
      }
    },

    onUpdateViewportUpper(newEnd) {
      this.controlsClicked = true;
      if (this.linked) {
        this.internalChartEndUpper = newEnd;
        this.internalChartEndLower = newEnd;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper == null
            ? this.rawChartStart
            : this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower == null
            ? this.optimizedChartStart
            : this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );
      } else {
        this.internalChartEndUpper = newEnd;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );
      }
    },

    onUpdateViewportLower(newEnd) {
      this.controlsClicked = true;
      if (this.linked) {
        this.internalChartEndUpper = newEnd;
        this.internalChartEndLower = newEnd;

        this.$emit(
          "updateUpperPagination",
          this.internalChartStartUpper == null
            ? this.rawChartStart
            : this.internalChartStartUpper,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower == null
            ? this.optimizedChartStart
            : this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );
      } else {
        this.internalChartEndLower = newEnd;

        this.$emit(
          "updateLowerPagination",
          this.internalChartStartLower,
          newEnd,
          this.topViewport,
          this.bottomViewport,
          true,
          this.expanded,
          false
        );
      }
    },
    // onUpdateViewportLower(newEnd) {
    //   this.controlsClicked = true;
    //   if (this.linked) {
    //     this.internalChartEndLower = newEnd;
    //     this.internalChartEndUpper = newEnd;
    //   } else {
    //     this.internalChartEndLower = newEnd;
    //   }
    // },

    onUpdateScaleUpper(newScale) {
      this.controlsClicked = true;
      if (this.linked) {
        this.scaleUpper = newScale;
        this.scaleLower = newScale;
      } else {
        this.scaleUpper = newScale;
      }
    },
    
    onUpdateScaleLower(newScale) {
      this.controlsClicked = true;
      if (this.linked) {
        this.scaleLower = newScale;
        this.scaleUpper = newScale;
      } else {
        this.scaleLower = newScale;
      }
    },

    onRowLabelWidthLowerDashboardButtonClick(value) {
      this.controlsClicked = true;
      if (this.linked) {
        this.rowLabelWidthLowerDashboard = value;
        this.rowLabelWidthUpperDashboard = value;

        this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
        this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;
      } else {
        this.rowLabelWidthLowerDashboard = value;
        this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;
      }
    },

    onRowLabelWidthUpperDashboardButtonClick(value) {
      this.controlsClicked = true;
      if (this.linked) {
        this.rowLabelWidthUpperDashboard = value;
        this.rowLabelWidthLowerDashboard = value;

        this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
        this.oldRowLabelWidthLowerDashboardValue = this.rowLabelWidthLowerDashboard;
      } else {
        this.rowLabelWidthUpperDashboard = value;
        this.oldRowLabelWidthUpperDashboardValue = this.rowLabelWidthUpperDashboard;
      }
    },

    onHideTimeaxisLowerDashboardButtonClick() {
      this.controlsClicked = true;
      if (this.linked) {
        this.hideTimeaxisLower = !this.hideTimeaxisLower;
        this.hideTimeaxisUpper = !this.hideTimeaxisUpper;
        this.hideTimeaxisLowerDashboard = !this.hideTimeaxisLowerDashboard;
        this.hideTimeaxisUpperDashboard = !this.hideTimeaxisUpperDashboard;
      } else {
        this.hideTimeaxisLower = !this.hideTimeaxisLower;
        this.hideTimeaxisLowerDashboard = !this.hideTimeaxisLowerDashboard;
      }
    },
    onHideTimeaxisUpperDashboardButtonClick() {
      this.controlsClicked = true;
      if (this.linked) {
        this.hideTimeaxisUpper = !this.hideTimeaxisUpper;
        this.hideTimeaxisLower = !this.hideTimeaxisLower;
        this.hideTimeaxisUpperDashboard = !this.hideTimeaxisUpperDashboard;
        this.hideTimeaxisLowerDashboard = !this.hideTimeaxisLowerDashboard;
      } else {
        this.hideTimeaxisUpper = !this.hideTimeaxisUpper;
        this.hideTimeaxisUpperDashboard = !this.hideTimeaxisUpperDashboard;
      }
    },
    isRawFileLoaded() {
      return (
        this.rawOperations > 0 || this.rawLeadTime > 0 || this.rawAvLeadTime > 0
      );
    },
    isOptimizedFileLoaded() {
      return (
        this.optOperations > 0 || this.optLeadTime > 0 || this.optAvLeadTime > 0
      );
    },
    animateUpperDashboard() {
      if (this.internalNoAnimation) {
        return;
      }

      if (document.getElementById("rawOperations"))
        document.getElementById("rawOperations").innerHTML = 0;
      if (document.getElementById("rawLeadTime"))
        document.getElementById("rawLeadTime").innerHTML = 0;
      if (document.getElementById("rawAvLeadTime"))
        document.getElementById("rawAvLeadTime").innerHTML = 0;

      let ANIMATION_COUNT_TIME = 0.8;
      let NUMBERS_ANIMATION_DELAY = 0.9;
      let tl = gsap.timeline();

      tl.from(
        ".dashboard",
        {
          // x: 400,
          y: 400,
          opacity: 0,
          duration: 0.8,
          ease: "Expo.easeOutIn"
        },
        0.5
      );

      let rawOperationsCount = { val: 0 },
        rawOperationsNewVal = this.rawOperations;
      let rawLeadTimeCount = { val: 0 },
        rawLeadTimeNewVal = this.rawLeadTime;
      let rawAvLeadTimeCount = { val: 0.0 },
        rawAvLeadTimeNewVal = this.rawAvLeadTime;

      tl.add("start")
        .to(
          rawOperationsCount,
          ANIMATION_COUNT_TIME,
          {
            val: rawOperationsNewVal,
            roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("rawOperations"))
                document.getElementById("rawOperations").innerHTML =
                  rawOperationsCount.val;
            }
          },
          NUMBERS_ANIMATION_DELAY
          // "start"
        )
        .to(
          rawLeadTimeCount,
          ANIMATION_COUNT_TIME,
          {
            val: rawLeadTimeNewVal,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("rawLeadTime"))
                document.getElementById(
                  "rawLeadTime"
                ).innerHTML = rawLeadTimeCount.val.toFixed(3);
            }
          },
          NUMBERS_ANIMATION_DELAY
          // "start"
        )
        .to(
          rawAvLeadTimeCount,
          ANIMATION_COUNT_TIME,
          {
            val: rawAvLeadTimeNewVal,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("rawAvLeadTime")) {
                document.getElementById(
                  "rawAvLeadTime"
                ).innerHTML = rawAvLeadTimeCount.val.toFixed(3);
              }
            }
          },
          NUMBERS_ANIMATION_DELAY
          // "start"
        ).then(() => {
          // console.log("this.isOptimizationLoaded: " + this.isOptimizationLoaded )
          // console.log("this.optimizedTreatedData: " + this.optimizedTreatedData)
          // if(!this.isOptimizationLoaded){
          //   this.emitter.emit("disableControlStates", false)
          // }
        });
    },
    animateLowerDashboard() {
      if (this.internalNoAnimation) {
        return;
      }

      if (document.getElementById("optOperations"))
        document.getElementById("optOperations").innerHTML = 0;
      if (document.getElementById("optLeadTime"))
        document.getElementById("optLeadTime").innerHTML = 0;
      if (document.getElementById("optAvLeadTime"))
        document.getElementById("optAvLeadTime").innerHTML = 0;

      let ANIMATION_COUNT_TIME = 0.8;
      let NUMBERS_ANIMATION_DELAY = 0.9;

      let tl = gsap.timeline();

      let optOperationsCount = { val: 0 },
        optOperationsNewVal = this.optOperations;
      let optLeadTimeCount = { val: 0 },
        optLeadTimeNewVal = this.optLeadTime;
      let optAvLeadTimeCount = { val: 0 },
        optAvLeadTimeNewVal = this.optAvLeadTime;

      tl.from(
        ".lower-dashboard",
        {
          // x: 400,
          y: 800,
          opacity: 0,
          duration: 0.8,
          ease: "Expo.easeOutIn"
        },
        0.5
      );

      // if (document.getElementById("boostCheck")) {
      //   tl.from(".boostCheck", {
      //     x: 400,
      //     opacity: 0,
      //     duration: 1,
      //     ease: "Expo.easeOutIn"
      //   });
      // }

      tl.add("start")
        .to(
          optOperationsCount,
          ANIMATION_COUNT_TIME,
          {
            val: optOperationsNewVal,
            roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("optOperations"))
                document.getElementById("optOperations").innerHTML =
                  optOperationsCount.val;
            }
          },
          NUMBERS_ANIMATION_DELAY
          // "start"
        )
        .to(
          optLeadTimeCount,
          ANIMATION_COUNT_TIME,
          {
            val: optLeadTimeNewVal,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("optLeadTime"))
                document.getElementById(
                  "optLeadTime"
                ).innerHTML = optLeadTimeCount.val.toFixed(3);
            }
          },
          NUMBERS_ANIMATION_DELAY
          // "start"
        )
        .to(
          optAvLeadTimeCount,
          ANIMATION_COUNT_TIME,
          {
            val: optAvLeadTimeNewVal,
            // roundProps: "val",
            onUpdate: function() {
              if (document.getElementById("optAvLeadTime"))
                document.getElementById(
                  "optAvLeadTime"
                ).innerHTML = optAvLeadTimeCount.val.toFixed(3);
            }
          },
          NUMBERS_ANIMATION_DELAY
        )
        .then(() => {
          this.hideResultsTemporarily = false;
          this.showResults = true;
          this.animateResults();
          
          // this.animateResults();
          this.emitter.emit("disableControlStates", false);
        });
    },
    animateResults() {
      // if (this.alreadyShowedResults) return;
      let tl = gsap.timeline();

      tl.from(".boostCheck", {
        x: 400,
        opacity: 0,
        duration: 1,
        ease: "Expo.easeOutIn"
      });

    this.alreadyShowedResults = true;
    },
    numberFormatThousands(num) {
      return num
        .toFixed(0) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
    },
    // numberFormatPercentage(num) {
    //   return (num * 100).toFixed(2);
    // },
    currencyFormatDE(num) {
      return (
        num
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
      ); // use . as a separator
    }
  }
};
</script>

<style lang="scss" scoped>
// .dark-panel {
//   background: #212121;
// }
// ::v-deep(.dark-panel.p-panel) {
//   .p-panel-titlebar {
//     background: #212121;
//   }
// }
@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid #dee2e6;
        > td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
</style>
